import { Component, OnInit, Injectable } from '@angular/core';
import { CoverageLineSearchFilterModel } from '../shared/module/SearchFilter/CoverageLines/CoverageLineSearchFilterModel';
import { AgenciesSearchFilterModel } from '../shared/module/SearchFilter/Agencies/AgenciesSearchFilterModel';
import { ProductSearchFilterModel } from '../shared/module/SearchFilter/Products/ProductSearchFilterModel';
import { SubscriptionSearchFilterModel } from '../shared/module/SearchFilter/Subscription/SubscriptionSearchFilterModel';
import { GeneralAgenciesSearchFilterModel } from '../shared/module/SearchFilter/Agencies/GeneralAgenciesSearchFilterModel';
import { CarriersSearchFilterModel } from '../shared/module/SearchFilter/Carriers/CarriersSearchFilterModel';
import { UserSearchFilterModel } from './module/SearchFilter/Users/UserSearchFilterModel';
import { AgenciesAssociatedBrokersSearchFilterModel } from './module/SearchFilter/Broker/AgenciesAssociatedBrokersSearchFilterModel';
import { GAAssociatedBrokersSearchFilterModel } from './module/SearchFilter/Broker/GAAssociatedBrokersSearchFilterModel';
import { RoleSearchFilterModel } from './module/SearchFilter/Roles/RoleSearchFilterModel';
import { ProgramTypeSearchFilter } from './module/ProgramTypeModels/ProgramTypeSearchFilter';
import { SalesCodeSearchFilter } from './module/SalesCode/SalesCodeSearchFilter';
import { CarrierPortfolioSearchFilterModel } from './module/SearchFilter/Carriers/CarrierPortfolioSearchFilterModel';
import { DocumentAndFormSearchFilterModel } from './module/SearchFilter/DocumentFormType/DocumentAndFormSearchFilterModel';
import { from, Observable } from 'rxjs';
import { CarrierDocumentListSearchFilterModel } from './module/SearchFilter/Carriers/CarrierDocumentListSearchFilterModel';
import { CensusSearchFilterModel } from './module/SearchFilter/Carriers/CensusSearchFilterModel';
import { CompositeSearchFilterModel } from './module/SearchFilter/Carriers/CompositeSearchFilterModel';
import { CountyFilterModel } from '../shared/module/SearchFilter/County/CounyFilterModel';
import { ZipCodeFilterModel } from '../shared/module/SearchFilter/ZipCode/ZipCodeFilterModel';
import { ProductAgeBangedFilterModel } from './module/Products/ProductAgeBangedFilterModel';
import { ProductAgeBandedCoverageTierModel } from './module/Products/ProductAgeBandedCoverageTierModel';
import { CoverageLineSearchModel } from './module/CoverageLines/CoverageLineSearchModel';
import { DocumentAndFormListFilter } from './module/DocumentAndFormTypeModels/DocumentAndFormListFilter';
import { SelectedItem } from './module/SelectedItem';
import { TierStructureSearchFilterModel } from './module/SearchFilter/TierStructure/TierStructureSearchFilterModel';
import { StateCompositeSearchFilterModel } from './module/SearchFilter/StateCompositeFactor/StateCompositeSearchFilter';
import { PortfolioCompositeSearchFilterModel } from './module/SearchFilter/Carriers/PortfolioCompositeSearchFilterModel';
import { FormFireCensusSearchFilterModel } from './module/SearchFilter/FormFireCensus/FormFireCensusSearchFilterModel';

@Injectable({
  providedIn: 'root'
})

export class SearchFilterComponent implements OnInit {

  coverageLineSearchFilterModel: CoverageLineSearchFilterModel;
  agenciesSearchFilterModel: AgenciesSearchFilterModel;
  productSearchFilterModel: ProductSearchFilterModel;
  generalAgenciesSearchFilterModel: GeneralAgenciesSearchFilterModel;
  carriersSearchFilterModel: CarriersSearchFilterModel;
  userSearchFilterModel: UserSearchFilterModel;
  agenciesAssociatedBrokersSearchFilterModel: AgenciesAssociatedBrokersSearchFilterModel;
  gaAssociatedBrokersSearchFilterModel: GAAssociatedBrokersSearchFilterModel;
  roleSearchFilterModel: RoleSearchFilterModel;
  programTypeSearchFilter: ProgramTypeSearchFilter;
  salesCodeSearchFilter: SalesCodeSearchFilter;
  carrierPortfolioSearchFilterModel: CarrierPortfolioSearchFilterModel;
  documentAndFormSearchFilterModel: DocumentAndFormSearchFilterModel;
  subscriptionSearchFilterModel: SubscriptionSearchFilterModel;
  countyFilterModel: CountyFilterModel;
  carrierDocumentListSearchFilterModel: CarrierDocumentListSearchFilterModel;
  censusSearchFilterModel: CensusSearchFilterModel;
  formFireCensusSearchFilterModel: FormFireCensusSearchFilterModel;
  compositeSearchFilterModel: CompositeSearchFilterModel;
  portfolioCompositeSearchFilterModel: PortfolioCompositeSearchFilterModel;
  stateCompositeSearchFilterModel: StateCompositeSearchFilterModel;
  tierStructureSearchFilterModel: TierStructureSearchFilterModel;
  zipCodeFilterModel: ZipCodeFilterModel;
  productAgeBangedFilterModel: ProductAgeBangedFilterModel;
  productAgeBandedCoverageTierModel: ProductAgeBandedCoverageTierModel;
  previousEffectiveDate: any;
  constructor() { }

  public ngOnInit() { }

  // Start Coverage Line State Maintain

  /*Check is exist coverage line search filter model data */
  CheckIsExistCoverageLineSearchFilterModelState() {
    if (this.coverageLineSearchFilterModel !== undefined
      && this.coverageLineSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Set coverage search model data */
  SetCoverageSearchModelState(coveragelineListSearch: any, selectedItems: number[]) {
    this.coverageLineSearchFilterModel = coveragelineListSearch;
    this.coverageLineSearchFilterModel.selectedItems = selectedItems;
  }

  /*Get coverage line search filter model */
  GetCoverageSearchModelState() {
    return this.coverageLineSearchFilterModel;
  }

  /*Get Selectecd coverage Tire search model */
  GetSelectecdCoverageTireSearchModelState() {
    return this.coverageLineSearchFilterModel.selectedItems;
  }

  /*Reset coverage line search filter model */
  ResetCoverageSearchModelState() {
    this.coverageLineSearchFilterModel = null;
  }

  // End here


  // Start Agencies Search Filter Model State Maintain

  /*Check is exist Agencies search filter model data */
  CheckIsExistAgenciesSearchFilterModel() {
    if (this.agenciesSearchFilterModel !== undefined
      && this.agenciesSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Agencies search filter model */
  GetAgenciesSearchModel() {
    return this.agenciesSearchFilterModel;
  }

  /*Set Agencies search filter model */
  SetAgenciesSearchModel(searchModel: any) {
    this.agenciesSearchFilterModel = searchModel;
  }

  /*Reset Agencies search filter model */
  ResetAgenciesSearchModelState() {
    this.agenciesSearchFilterModel = null;
  }

  // End here


  // Start Products Search Filter Model State Maintain

  /*Check is exist Products search filter model data */
  CheckIsExistProductsSearchFilterModel() {
    if (this.productSearchFilterModel !== undefined
      && this.productSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Products search filter model */
  GetProductsSearchModel() {
    return this.productSearchFilterModel;
  }
  /*Check is exist Subscription Plan search filter model data */
  CheckIsExistSubscriptionSearchFilterModel() {
    if (this.subscriptionSearchFilterModel !== undefined
      && this.subscriptionSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  GetSubscriptionSearchFilterModel() {
    return this.subscriptionSearchFilterModel;
  }

  /*Set Products search filter model */
  SetProductsSearchModel(filter: any, stateSelectedItems: any, coverageLinesSelectedItems: any) {
    this.productSearchFilterModel = filter;
    this.productSearchFilterModel.stateSelectedItems = stateSelectedItems;
    this.productSearchFilterModel.coverageLinesSelectedItems = coverageLinesSelectedItems;
  }

  /*Get Selected states search filter model */
  GetSelectedStatesSearchModel() {
    return this.productSearchFilterModel.stateSelectedItems;
  }

  /*Get Selected coverage line items */
  GetSelectedCoverageLinesItems() {
    return this.productSearchFilterModel.coverageLinesSelectedItems;
  }

  /*Reset Products search filter model */
  ResetProductsSearchModelState() {
    this.productSearchFilterModel = null;
  }
  // End here

  /*Reset Subscriptions search filter model */
  ResetSubscriptionsSearchModelState() {
    this.subscriptionSearchFilterModel = null;
  }
  // End here


  // Start General Agencies State Maintain

  /*Check is exist General Agencies search filter model */
  CheckIsExistGeneralAgenciesSearchFilterModel() {
    if (this.generalAgenciesSearchFilterModel !== undefined
      && this.generalAgenciesSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get General Agencies search filter model */
  GetGeneralAgenciesSearchModel() {
    return this.generalAgenciesSearchFilterModel;
  }

  /*Set General Agencies search filter model */
  SetGeneralAgenciesSearchModel(searchModel: any) {
    this.generalAgenciesSearchFilterModel = searchModel;
  }

  /*Reset General Agencies search filter model */
  ResetGeneralAgenciesSearchModelState() {
    this.generalAgenciesSearchFilterModel = null;
  }

  // End here


  // Start Carriers State Maintain

  /*Check is exist Carriers search filter model */
  CheckIsExistCarriersSearchFilterModel() {
    if (this.carriersSearchFilterModel !== undefined
      && this.carriersSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers search filter model */
  GetCarriersSearchModel() {
    return this.carriersSearchFilterModel;
  }

  /*Reset Carriers search filter model */
  ResetCarriersSearchModelState() {
    this.carriersSearchFilterModel = null;
  }

  /*Set Carriers search filter model */
  SetCarriersSearchModel(searchListFilter: CarriersSearchFilterModel) {
    this.carriersSearchFilterModel = searchListFilter;
  }

  // End here



  // Start Form Fire Users State Maintain

  /*Check is exist Form Fire Users search filter model */
  CheckIsExistFormFireUsersSearchFilterModel() {
    if (this.userSearchFilterModel !== undefined
      && this.userSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Form Fire Users search filter model */
  GetFormFireUsersSearchModel() {
    return this.userSearchFilterModel;
  }

  /*Set Form Fire Users search filter model */
  SetFormFireUsersSearchModel(userListSearch: UserSearchFilterModel) {
    this.userSearchFilterModel = userListSearch;
  }

  /*Reset Form Fire Users search filter model */
  ResetFormFireUsersSearchModelState() {
    this.userSearchFilterModel = null;
  }

  /*Get Roles List search filter */
  GetRolesList() {
    return this.userSearchFilterModel.roleList;
  }

  // End here


  // Start Agencies Associated Brokers State Maintain

  /*Check is exist Broker search filter model */
  CheckIsExistBrokerSearchFilterModel(agencyId: any) {
    if (this.agenciesAssociatedBrokersSearchFilterModel !== undefined && this.agenciesAssociatedBrokersSearchFilterModel !== null
      && this.agenciesAssociatedBrokersSearchFilterModel.agencyId === agencyId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Broker search filter model */
  GetBrokerSearchModel() {
    return this.agenciesAssociatedBrokersSearchFilterModel;
  }

  /*Set Broker search filter model */
  SetBrokerSearchModel(searchFilter: any) {
    this.agenciesAssociatedBrokersSearchFilterModel = searchFilter;
  }

  /*Reset Broker search filter model */
  ResetBrokerSearchModelState() {
    this.agenciesAssociatedBrokersSearchFilterModel = null;
  }

  // End here


  // Start General Agencies Associated Brokers State Maintain

  /*Check is exist Broker search filter model */
  CheckIsExistGAABrokerSearchFilterModel(generalAgencyId: any) {
    if (this.gaAssociatedBrokersSearchFilterModel !== undefined && this.gaAssociatedBrokersSearchFilterModel !== null
      && this.gaAssociatedBrokersSearchFilterModel.agencyId === generalAgencyId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Broker search filter model */
  GetGAABrokerSearchModel() {
    return this.gaAssociatedBrokersSearchFilterModel;
  }


  /*Set Broker search filter model */
  SetGAABrokerSearchModel(searchFilter: any) {
    this.gaAssociatedBrokersSearchFilterModel = searchFilter;
  }

  /*Reset Broker search filter model */
  ResetGAABrokerSearchModelState() {
    this.gaAssociatedBrokersSearchFilterModel = null;
  }

  // End here


  // Start Role Search Filter Model State Maintain

  /*Check is exist Role search filter model data */
  CheckIsExistRoleSearchFilterModel() {
    if (this.roleSearchFilterModel !== undefined
      && this.roleSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Role search filter model */
  GetRoleSearchModel() {
    return this.roleSearchFilterModel;
  }

  /*Set Role search filter model */
  SetRoleSearchModel(searchModel: RoleSearchFilterModel, roleTypeSelectedItems: SelectedItem[]) {
    this.roleSearchFilterModel = searchModel;
    this.roleSearchFilterModel.roleTypeSelectedItems = roleTypeSelectedItems;
  }

  /*Get Selected Role Type search filter model */
  GetSelectedRoleTypeSearchModel() {
    return this.roleSearchFilterModel.roleTypeSelectedItems;
  }


  /*Reset Role search filter model */
  ResetRoleSearchModelState() {
    this.roleSearchFilterModel = null;
  }
  // End here


  // Start Role Search Filter Model State Maintain

  /*Check is exist Program Type search filter model data */
  CheckIsExistProgramTypeSearchFilterModel() {
    if (this.programTypeSearchFilter !== undefined
      && this.programTypeSearchFilter !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Program Type search filter model */
  GetProgramTypeSearchModel() {
    return this.programTypeSearchFilter;
  }

  /*Reset Program Type search filter model */
  ResetProgramTypeSearchModelState() {
    this.programTypeSearchFilter = null;
  }

  /*Set Program Type search filter model */
  SetProgramTypeSearchModel(searchListFilter: ProgramTypeSearchFilter) {
    this.programTypeSearchFilter = searchListFilter;
  }

  // End here


  // Start Sales Code Search Filter Model State Maintain

  /*Check is exist Sales Code search filter model data */
  CheckIsExistSalesCodeSearchFilterModel() {
    if (this.salesCodeSearchFilter !== undefined
      && this.salesCodeSearchFilter !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Sales Code search filter model */
  GetSalesCodeSearchModel() {
    return this.salesCodeSearchFilter;
  }

  /*Reset Sales Code search filter model */
  ResetSalesCodeSearchModelState() {
    this.salesCodeSearchFilter = null;
  }

  /*Set Sales Code search filter model */
  SetSalesCodeSearchModel(searchListFilter: SalesCodeSearchFilter) {
    this.salesCodeSearchFilter = searchListFilter;
  }

  // End here

  // Start Carriers Product Portfolio Search Filter Model State Maintain

  /*Check is exist Carriers Product search filter model data */
  CheckIsExistCarriersPortfolioSearchFilterModel(carrierId: any) {
    if (this.carrierPortfolioSearchFilterModel !== undefined
      && this.carrierPortfolioSearchFilterModel !== null && carrierId === this.carrierPortfolioSearchFilterModel.CarrierId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers Product search filter model */
  GetCarriersProductSearchModel() {
    return this.carrierPortfolioSearchFilterModel;
  }

  /*Set Carriers Product search filter model */
  SetCarriersProductSearchModel(searchModel: CarrierPortfolioSearchFilterModel) {
    this.carrierPortfolioSearchFilterModel = searchModel;
  }

  /*Reset Carriers Product search filter model */
  ResetCarriersProductSearchModelState() {
    this.carrierPortfolioSearchFilterModel = null;
  }

  // End here


  // Start Document & Form Type Search Filter Model State Maintain

  /*Check is exist Document & Form Type search filter model data */
  CheckIsExistDocumentFormTypeSearchFilterModel() {
    if (this.documentAndFormSearchFilterModel !== undefined
      && this.documentAndFormSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Document & Form Type search filter model */
  GetDocumentFormTypeSearchModel() {
    return this.documentAndFormSearchFilterModel;
  }

  /*Set Document & Form Type search filter model */
  SetDocumentFormTypeSearchModel(searchListFilter: DocumentAndFormListFilter) {
    this.documentAndFormSearchFilterModel = searchListFilter;
  }

  /*Reset Document and FormType search filter model */
  ResetDocumentFormTypeSearchModelState() {
    this.documentAndFormSearchFilterModel = null;
  }
  // End here


  // Start Carrier Document List Search Filter Model State Maintain

  /*Check is exist Carrier Document List search filter model data */
  CheckIsExistCarrierDocumentListSearchFilterModel(carrierId) {
    if (this.carrierDocumentListSearchFilterModel !== undefined
      && this.carrierDocumentListSearchFilterModel !== null && this.carrierDocumentListSearchFilterModel.CarrierId === carrierId) {
      return true;
    } else {
      return false;
    }
  }

  GetCarrierDocumentListSearchModel() {
    return this.carrierDocumentListSearchFilterModel;
  }

  SetCarrierDocumentListSearchModel(searchListFilter: CarrierDocumentListSearchFilterModel) {
    this.carrierDocumentListSearchFilterModel = searchListFilter;
  }

  ResetCarrierDocumentListSearchModelState() {
    this.carrierDocumentListSearchFilterModel = null;
  }

  // End here


  GetSubscriptionSearchModel() {
    return this.subscriptionSearchFilterModel;
  }

  SetSubscriptionSearchModel(filter: SubscriptionSearchFilterModel) {
    this.subscriptionSearchFilterModel = filter;
  }

  /*Check is exist Products search filter model data */
  CheckIsExistSubscriptionsSearchFilterModel() {
    if (this.subscriptionSearchFilterModel !== undefined
      && this.subscriptionSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }
  // Check is exist ProductAgeBangedFilterModel data
  CheckIsExistProductAgeBangedFilterModel() {
    if (this.productAgeBangedFilterModel) {
      if (this.productAgeBangedFilterModel.zipCode !== '') {
        return true;
      }
      if (this.productAgeBangedFilterModel.effectiveDate < this.GetPreviousEffectiveDate) {
        return true;
      }
    } else {
      return false;
    }
  }

  GetProductAgeBangedFilterModel() {
    return this.productAgeBangedFilterModel;
  }

  SetProductAgeBangedFilterModel(filter: any) {
    this.productAgeBangedFilterModel = filter;
  }

  // county search text save
  GetCountyFilterModel() {
    return this.countyFilterModel;
  }
  SetPreviousEffectiveDate(val: any) {
    this.previousEffectiveDate = val;
  }
  GetPreviousEffectiveDate(): any {
    return this.previousEffectiveDate;
  }

  /*Check is exist county filter model data for search save */
  CheckIsExistCountyFilterModel() {
    if (this.countyFilterModel !== undefined
      && this.countyFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  SetCountyFilterModel(filter: any) {
    this.countyFilterModel = filter;
  }

  ResetCountyFilterModelState() {
    this.countyFilterModel = null;
  }

  // zipcode search text save
  GetZipCodeFilterModel() {
    return this.zipCodeFilterModel;
  }

  /*Check is exist zipcode filter model data for search save */
  CheckIsExistZipCodeFilterModel() {
    if (this.zipCodeFilterModel !== undefined
      && this.zipCodeFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  SetZipCodeFilterModel(filter: any) {
    this.zipCodeFilterModel = filter;
  }

  ResetZipCodeFilterModelState() {
    this.zipCodeFilterModel = null;
  }


  // Start Census Search Filter Model State Maintain

  /*Check is exist Census search filter model data */
  CheckIsExistCensusSearchFilterModel(carrierId: any) {
    if (this.censusSearchFilterModel !== undefined
      && this.censusSearchFilterModel !== null && carrierId === this.censusSearchFilterModel.carrierId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers Product search filter model */
  GetCensusSearchModel() {
    return this.censusSearchFilterModel;
  }

  /*Set Carriers Product search filter model */
  SetCensusSearchModel(searchModel: CensusSearchFilterModel) {
    this.censusSearchFilterModel = searchModel;
  }

  /*Reset Carriers Product search filter model */
  ResetCensusSearchModelState() {
    this.censusSearchFilterModel = null;
  }

  // End here

  // Start Portfolio Composite Search Filter Model State Maintain

  /*Check is exist Portfolio Composite search filter model data */
  CheckIsExistPortfolioCompositeSearchFilterModel(carrierPortfolioId: any) {
    if (this.portfolioCompositeSearchFilterModel !== undefined
      && this.portfolioCompositeSearchFilterModel !== null &&
      carrierPortfolioId === this.portfolioCompositeSearchFilterModel.carrierPortfolioId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers Portfolio Composite search filter model */
  GetPortfolioCompositeSearchModel() {
    return this.portfolioCompositeSearchFilterModel;
  }

  /*Set Carriers Portfolio Composite search filter model */
  SetPortfolioCompositeSearchModel(searchModel: PortfolioCompositeSearchFilterModel) {
    this.portfolioCompositeSearchFilterModel = searchModel;
  }

  /*Reset Carriers Portfolio Composite search filter model */
  ResetPortfolioCompositeSearchModelState() {
    this.portfolioCompositeSearchFilterModel = null;
  }

  // End here

   // Start Composite Search Filter Model State Maintain

  /*Check is exist Census search filter model data */
  CheckIsExistCompositeSearchFilterModel(carrierId: any) {
    if (this.compositeSearchFilterModel !== undefined
      && this.compositeSearchFilterModel !== null && carrierId === this.compositeSearchFilterModel.carrierId) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers Product search filter model */
  GetCompositeSearchModel() {
    return this.compositeSearchFilterModel;
  }

  /*Set Carriers Product search filter model */
  SetCompositeSearchModel(searchModel: CompositeSearchFilterModel) {
    this.compositeSearchFilterModel = searchModel;
  }

  /*Reset Carriers Product search filter model */
  ResetCompositeSearchModelState() {
    this.compositeSearchFilterModel = null;
  }

  // End here

    // Start State Composite Search Filter Model State Maintain

  /*Check is exist Census search filter model data */
  CheckIsExistStateCompositeSearchFilterModel() {
    if (this.stateCompositeSearchFilterModel !== undefined
      && this.stateCompositeSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get Carriers Product search filter model */
  GetStateCompositeSearchModel() {
    return this.stateCompositeSearchFilterModel;
  }

  /*Set Carriers Product search filter model */
  SetStateCompositeSearchModel(searchModel: StateCompositeSearchFilterModel) {
    this.stateCompositeSearchFilterModel = searchModel;
  }

  /*Reset Carriers Product search filter model */
  ResetStateCompositeSearchModelState() {
    this.stateCompositeSearchFilterModel = null;
  }

  // End here

   // Start TierStructure Search Filter Model State Maintain

  /*Check is exist TierStructure search filter model data */
  CheckIsExistTierStructureSearchFilterModel() {
    if (this.tierStructureSearchFilterModel !== undefined
      && this.tierStructureSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get TierStructure search filter model */
  GetTierStructureSearchModel() {
    return this.tierStructureSearchFilterModel;
  }

  /*Set TierStructure search filter model */
  SetTierStructureSearchModel(searchModel: TierStructureSearchFilterModel) {
    this.tierStructureSearchFilterModel = searchModel;
  }

  /*Reset TierStructure search filter model */
  ResetTierStructureSearchModelState() {
    this.tierStructureSearchFilterModel = null;
  }

  // End here
   /*Reset TierStructure search filter model */
   ResetProductAgeBandedSearchModelState() {
    this.productAgeBangedFilterModel = null;
  }

  // End here

  // Start formfire census Search Filter Model State Maintain

  /*Check is exist formfire census search filter model data */
  CheckIsExistFormFireCensusSearchFilterModel() {
    if (this.censusSearchFilterModel !== undefined
      && this.censusSearchFilterModel !== null) {
      return true;
    } else {
      return false;
    }
  }

  /*Get formfire census search filter model */
  GetFormFireCensusSearchModel() {
    return this.censusSearchFilterModel;
  }

  /*Set formfire census search filter model */
  SetFormFireCensusSearchModel(searchModel: CensusSearchFilterModel) {
    this.censusSearchFilterModel = searchModel;
  }

  /*Reset formfire census search filter model */
  ResetFormFireCensusSearchModelState() {
    this.censusSearchFilterModel = null;
  }

  // End here
}
