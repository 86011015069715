import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderInterceptor } from '../app/Interceptors/HeaderInterceptor';
import { MatProgressSpinnerModule  } from "@angular/material/progress-spinner";
import { AuthenticationService } from '../app/services/authentication.service';
import { GlobalSharedService } from '../app/services/global-shared.service';
import { ApiService } from '../app/services/api.service';
import { FormFireApiService } from '../app/services/formfire-api.service';
import { RoleBaseAccessRights } from '././shared/role-base-access-rights';
import { SharedServices } from './services/shared.service';
import { MatCardModule } from '@angular/material/card';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import GlobalConfig from './global-config';
import { MasterAPICall } from './shared/master-api.component';
import { AuthService } from './auth/auth.service';
import { IdentityRolesService } from './services/identity-roles.service';
import { AuthConfigService } from './auth/auth-config.service';
import { ExampleTestComponent } from './modules/example-test/example-test.component';

export function initializeApp1(
  appInitService: MasterAPICall) {
    return(): Promise<any> => {
      return appInitService.appInitialCall();
    };
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    PageNotFoundComponent,
    ExampleTestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    AngularMultiSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    TextMaskModule,
    MatCardModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          GlobalConfig.IDENTITY_SERVICE_HOST,
        ],
        sendAccessToken: true
      }
    })
  ],
  exports: [],
  providers: [
    AuthenticationService, SharedServices, AuthService, IdentityRolesService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    GlobalSharedService,
    AuthConfigService,
    ApiService,
    FormFireApiService,
    { provide: OAuthStorage, useValue: localStorage },
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [MasterAPICall], multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
