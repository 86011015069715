import { CarrierContactDetailsModel } from './CarrierContactDetailsModel';
import { CarrierAddressModel } from './CarrierAddressModel';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsModel } from './TermsAndConditionsModel';


export class CarrierModel {
    carrierName: string;
    contactNumber: string;
    networkDetails: string;
    daysToAcceptApplication: string;
    rateRoundingOptionId: number;
    planSelectionRequiresTobacco: boolean;
    requireCarrierCodeToSendForm: boolean;
    restrictFromPreviewingForm: boolean;
    showPreviewFormReminder: boolean;
    carrierLogoImage: any;
    FileType: string;
    Tags: string[] = [];
    CarrierImageUrl: string;
    CarrierLogo: string;
    carrierContactDetails: CarrierContactDetailsModel[] = [];
    carrierAddress: CarrierAddressModel[] = [];
    SubscriptionPlanId: number;
    PlanEffectiveStartDate: any;
    PlanEffectiveEndDate: any;
    restrictCarrierByProgram: boolean;
    ProgramTypeList: any[] = [];
    EditProgramTypeList: any[] = [];
    IsActive: boolean;
    requiresFormPreview: boolean;
    prescreenTermsAndConditions: TermsAndConditionsModel = new TermsAndConditionsModel();
    enrollmentTermsAndConditions: TermsAndConditionsModel = new TermsAndConditionsModel();
    termsAndConditions: TermsAndConditionsModel[] = [];
}
