import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import GlobalConfig from "../global-config";
import {
  IdentityUser,
  UserListPagingModel,
  UserPasswordSetupModel,
  UserPasswordUpdateModel,
} from "../shared/module/IdentityUser";
import { UserListSearch } from "../shared/module/UserListSearch";
import { FormFireApiService } from "./formfire-api.service";

@Injectable({
  providedIn: "root",
})
export class IdentityUsersService {
  url = GlobalConfig.IDENTITY_SERVICE_HOST;

  constructor(private formFireApiService: FormFireApiService) {}

  SearchUsers(searchModel: UserListSearch) {
    let queryString = "?roleTypeId=" + searchModel.roleTypeId;

    queryString = this.SetQueryString(queryString, "name", searchModel.Name);
    queryString = this.SetQueryString(
      queryString,
      "roleId",
      searchModel.RoleId
    );
    queryString = this.SetQueryString(
      queryString,
      "includeInactive",
      searchModel.IsInactive
    );

    if (searchModel.PageSize)
      queryString = this.SetQueryString(queryString, "PageSize", searchModel.PageSize.toString());
    if (searchModel.PageNumber)
      queryString = this.SetQueryString(queryString, "PageNumber", searchModel.PageNumber.toString());
    if (searchModel.SortOrder)
      queryString = this.SetQueryString(queryString, "SortOrder", searchModel.SortOrder.toString());
    if (searchModel.SortBy)
      queryString = this.SetQueryString(queryString, "SortBy", searchModel.SortBy.toString());

    return this.formFireApiService.get<UserListPagingModel>(
      this.url + "user" + queryString
    );
  }

  GetUserDetails(userId: number) {
    return this.formFireApiService.get<IdentityUser>(
      this.url + "user?id=" + userId
    );
  }

  GetUserNames(searchText: string, roleType: number): Observable<any[]> {
    const queryString = "?roleTypeId=" + roleType + "&name=" + searchText;
    let response = new Subject<any>();
    this.formFireApiService
      .get<any>(this.url + "user/user-names" + queryString)
      .subscribe((x) => {
        response.next(x.result ? x.result : []);
      });
    return response.asObservable();
  }

  // Update - Activate - Deactivate - Lock - Unlock
  UpdateUser(identityUserModel: IdentityUser) {
    return this.formFireApiService.put<number>(
      this.url + "user/",
      identityUserModel
    );
  }

  CreateUser(identityUserModel: IdentityUser) {
    return this.formFireApiService.post<number>(
      this.url + "user/",
      identityUserModel
    );
  }

  DeleteUser(userId: number) {
    return this.formFireApiService.delete<string>(
      this.url + "user?id=" + userId
    );
  }

  IsEmailIdAlreadyExist(email: string, userId: number, roleTypeId: number) {
    let queryString = "?roleTypeId=" + roleTypeId;
    queryString = this.SetQueryString(queryString, "id", userId);
    queryString = this.SetQueryString(queryString, "email", email);

    return this.formFireApiService.get<IdentityUser>(
      this.url + "user" + queryString
    );
  }

  SaveUserPassword(model: UserPasswordSetupModel) {
    return this.formFireApiService.put(
      this.url + "user/user-confirmation",
      model
    );
  }
  UserVerification(userId: number) {
    return this.formFireApiService.put(
      this.url + "user/user-verification?userId=" + userId
    );
  }

  ChangePassword(model: UserPasswordUpdateModel) {
    return this.formFireApiService.put(
      this.url + "user/user-password",
      model
    );
  }

  private SetQueryString(queryString: string, paramName: string, paramValue) {
    return paramValue
      ? queryString + "&" + paramName + "=" + paramValue
      : queryString;
  }

  GetIfUserHaveProxyLogin() {
    return this.formFireApiService.get<boolean>(
      this.url + "Impersonation/HaveProxyLogin"
    );
  }
}
