import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleBaseAccessRights } from './shared/role-base-access-rights';
import { AuthService } from './auth/auth.service';
import { AuthConfigService } from './auth/auth-config.service';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private isAuthenticated: boolean;
  private isDoneLoading: boolean;
  constructor(public accessRights: RoleBaseAccessRights, private authService: AuthService) {
    this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
    this.authService.isDoneLoading$.subscribe(i => this.isDoneLoading = i);
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean> | boolean | Promise<boolean> {
    const moduleName = route.data.ExpectedModuleName as any;

    if (this.authService.hasValidToken()) {
      return true;
    }

    if (!this.authService.hasValidToken() && this.isDoneLoading === undefined) {
      this.authService.runInitialLogin().then(_ => {
        return Promise.resolve(this.authService.validateAccessRight(moduleName));
      }).then(_ => {
        if (!this.isAuthenticated) {
          return Promise.resolve(false);
        }
      });
    } else {
      return this.authService.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(_ => { this.isAuthenticated || this.authService.login() }))
      .pipe(map(_ => this.isAuthenticated));
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class ChildAuthGuard implements CanActivate {
  private isAuthenticated: boolean;
  private isDoneLoading: boolean;
  constructor(public accessRights: RoleBaseAccessRights, private authService: AuthService) {
    this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
    this.authService.isDoneLoading$.subscribe(i => this.isDoneLoading = i);
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean> | boolean | Promise<boolean> {
    const accessRightName = route.data.ExpectedAccessRightName as string;
    
    if (this.authService.hasValidToken()) {
      return true;
    }

    if (!this.authService.hasValidToken() && this.isDoneLoading === undefined) {
      this.authService.runInitialLogin().then(_ => {
        return Promise.resolve(this.authService.validateAccessRight(accessRightName));
      }).then(_ => {
        if (!this.isAuthenticated) {
          return Promise.resolve(false);
        }
      });
    } else {
      return this.authService.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(_ => { this.isAuthenticated || this.authService.login() }))
      .pipe(map(_ => this.isAuthenticated));
    }
  }
}


// IDENTITY:
@Injectable({
  providedIn: 'root'
})
export class IdentityAuthGuard implements CanActivate {

  private isAuthenticated: boolean;
  private isDoneLoading: boolean;
  constructor(private authService: AuthService, private authConfigService: AuthConfigService) {
    this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
    this.authService.isDoneLoading$.subscribe(i => this.isDoneLoading = i);
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,state: RouterStateSnapshot,
  ): Observable<boolean> | boolean | Promise<boolean> {

    if (this.authService.hasValidToken()) {
      return true;
    }

    if (!this.authService.hasValidToken() && this.isDoneLoading === undefined) {
      this.authService.runInitialLogin().then(_ => {
        return Promise.resolve(true);
      }).then(_ => {
        if (!this.isAuthenticated) {
          return Promise.resolve(false);
        }
      });
    }

    return this.authService.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(_ => { this.isAuthenticated || this.authService.runInitialLogin() }))
      .pipe(map(_ => this.isAuthenticated));
  }
}
