import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {RoleBaseAccessRights} from '../../role-base-access-rights';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})

export class ErrorComponent implements OnInit, OnDestroy {

  constructor(private roleBasedAccess: RoleBaseAccessRights, @Inject(DOCUMENT) private document: Document) {
    this.roleBasedAccess.DispalySideBarAndHeader();
  }


 ngOnInit() {
  this.document.body.classList.add('no-access-right');
}

ngOnDestroy() {
  this.document.body.classList.remove('no-access-right');
}

}
