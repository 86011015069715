import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { SharedServices } from '../../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchFilterComponent } from '../../shared/search-filter-component';
import { AuthService } from '../../auth/auth.service';
import { AuthConfigService } from 'src/app/auth/auth-config.service';
import GlobalConfig from 'src/app/global-config';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() branding;

  constructor(
    private router: Router, public AuthService: AuthenticationService, private oAuthService: AuthService, private oauthConfigService: AuthConfigService,
    public sharedService: SharedServices, public domSanitizer: DomSanitizer, private searchFilterComponent: SearchFilterComponent) {
  }

  ngOnInit() {
    this.initFunctions();
  }

  getUserName(): string {
    const user = this.sharedService.GetLoggedUser;
    return user ? user.firstName + ' ' + user.lastName : '';
  }

  // Logout User
  public DoLogout() {
    // oauth2 logout:
    this.oAuthService.logout();

    this.AuthService.ClearToken();
    sessionStorage.removeItem('loggedInUserName');
    this.router.navigate(['/profile']);
    this.sharedService.ClearGlobalData();
  }

  public RedirectToFFLegacy() {
    window.location.href = GlobalConfig.AGENCY_WEBAPP_URL;
  }


  initFunctions(){


  $(document).ready(function () {
    var originalSize = $("html").css("font-size");

    // Increase Font Size
    $(".increase").click(function () {
      var currentSize = $("html").css("font-size");
      var currentSize = parseFloat(currentSize) + 2 + "px";
      $("html").css("font-size", currentSize);
      return false;
    });

    // Decrease Font Size
    $(".decrease").click(function () {
      var currentFontSize = $("html").css("font-size");
      var currentSize = $("html").css("font-size");
      var currentSize = parseFloat(currentSize) - 2 + "px";
      $("html").css("font-size", currentSize);
      return false;
    });

    // reset
    $(".resetMe").click(function () {
      $("html").css("font-size", originalSize);
    });
  });

  //kyepad hide on ios devices on touch code here

  $(function () {
    var cacheInput = null;
    var timer = null;
    if (!isApple()) {
      return false;
    }
    $(document).on("focus", "input", function (e) {
      cacheInput = e.target;
    });
    $(document).on("focus", "textarea", function (e) {
      cacheInput = e.target;
    });
    $(document).on("touchend", function (e) {
      if (e.target.documentElement.tagName !== 'INPUT' && e.target.documentElement.tagName !== 'TEXTAREA') {
        if (cacheInput !== null) {
          timer = setTimeout(function () {
            cacheInput.blur();
            clearTimeout(timer);
          })
        }
      }
    });
    function isApple() {
      var ua = navigator.userAgent.toUpperCase();
      var ipad = ua.indexOf("IPAD") > -1,
        ipod = ua.indexOf("IPOD") > -1,
        iphone = ua.indexOf("IPHONE") > -1;
      return ipad || ipod || iphone;
    }
  });

  (function () {
    if (!sessionStorage.length) {
      // Ask other tabs for session storage
      localStorage.setItem("getSessionStorage", Date.now().toString());
    }
    window.addEventListener("storage", function (event) {
      if (event.key == "getSessionStorage") {
        // Some tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        localStorage.removeItem("sessionStorage");
      } else if (event.key == "sessionStorage" && !sessionStorage.length) {

        let data = JSON.parse(event.newValue);
        $.each(data, function(key,value)
        {
          sessionStorage.setItem(key.toString(), value);
        });
      }
    });
  })();


  }

}
