import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';


@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const key = 'preload';
    if (route.data && route.data[key]) {
      return load();
    } else {
      return Observable.of(null);
    }
  }
}

export class CustomPreloadingService {

  constructor() { }
}
