export class AppSettings {
 PaginationArray = [20, 30, 40];
 popupShowTime = 5;
 CoverageLineCaptcha = 'DELETE';
 kS = 256;
 iS = 128;
 sS = 256;
 iT = 1;
 userIdIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
 EditorToolBar = [
    ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
    ['fontName', 'fontSize', 'color'],
    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
    ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
    ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
    ['link', 'unlink']
  ];
  EditorTranslate =  'no';
  EditorMinHeight = '5rem';
  EditorHeight = '10rem';
  Placeholder = '';
  AgencyNameEllipse = 40;
  FileUploadSize = 20971520;
}
