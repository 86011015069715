import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Datacenter, datadogLogs } from "@datadog/browser-logs";
import GlobalConfig from './app/global-config';

if (GlobalConfig.PRODUCTION) {
  enableProdMode();
}

datadogLogs.init({
  clientToken: 'pubc78878a559ebf74983fa0380df08d235',
  datacenter: Datacenter.US,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: GlobalConfig.Environment + ' SysAdmin',
  env: GlobalConfig.Environment
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
