import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { LoggingService } from './logging.service';
import GlobalConfig from '../global-config';

export enum HttpReqMethod {
  POST,
  GET,
  DELETE,
  PUT
}

interface IHeaderItem {
  name: string,
  value: any
}

export interface IRequest {
  url: string,
  method: HttpReqMethod,
  body?: object,
  params?: HttpParams,
  headers?: Array<IHeaderItem>
}

// DEPRECATED - This API Provider does not conform to the new API standards at the time the Enrollments service has been deployed.
// Going forward, use the FormFireApiService which correctly maps responses.
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient, private loggingService: LoggingService) { }

  public Request<T>(url: string, method: string, body?: object, parameters?: HttpParams,headers?: HttpHeaders) {
    try {
      if (method === HttpReqMethod.GET.toString()) {
        if (parameters) {
          return this.httpClient.get<T>(url, { params: parameters,headers: headers });
        }
        return this.httpClient.get<T>(url,{headers: headers});
      } else if (method === HttpReqMethod.POST.toString()) {
        return this.httpClient.post<T>(url, body,{headers: headers});
      } else if (method === HttpReqMethod.DELETE.toString()) {
        const httpOptions = {
          headers: headers, body: body
      };
        return this.httpClient.delete<T>(url ,httpOptions);
      } else if (method === HttpReqMethod.PUT.toString()) {
        return this.httpClient.put<T>(url, body,{headers: headers});
      }
    } catch (error) {
      this.LogError('Error In api.service.ts at Method: Request. ' + error);
    }
  }

  public request<T>({ url, method, body, params, headers }: IRequest) {
    const httpHeaders = this.buildHeaders(headers)
    return this.Request<T>(url, method.toString(), body, params, httpHeaders)
  }

  private buildHeaders(items: Array<IHeaderItem>) {
    if (items && items.length > 0) {
      let headers = new HttpHeaders()
      for (const item of items) {
        headers = headers.set(item.name, item.value.toString())
      }

      return headers
    }

    return null
  }
    public getFile<T>(url: string, params?: any, defaultResult?: T,myHeader?:HttpHeaders): Observable<any> {
    return this.httpClient.get(url, {
    headers: myHeader,
    params: params,
    responseType: 'blob'
    })};

    public getFilebyPost<T>(url: string,body:any, params?: any, defaultResult?: T,myHeader?:HttpHeaders): Observable<any> {

     return this.httpClient.post(url,
      body,
     {
      headers: myHeader,
      params: params,
      responseType: 'blob'
      })
    };


  public async LogError(exception: string) {
    this.CallError(exception).subscribe(x => { });
  }

  public CallError(exception: string): Observable<any> {

    const error = new Error(exception);

    this.loggingService.logException(error);

    return this.httpClient.get<any>(GlobalConfig.ADMIN_SERVICE_HOST + 'api/error/LogError?exception=' + exception);

  }

}

