import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-example-test',
  templateUrl: './example-test.component.html',
  styleUrls: ['./example-test.component.scss']
})
export class ExampleTestComponent implements OnInit {

  testNumber: number;

  constructor() { }

  ngOnInit(): void {
    this.testNumber = 99;
  }
}
