export enum EnvironmentEnum  {
    LOCAL = "LOCAL",
    CI = "CI",
    QA = "QA",
    UAT = "UAT",
    PROD = "PROD",
    CIContainerApps = "CIContainerApp",
    QAContainerApps = "QAContainerApp",
    UATContainerApps = "UATContainerApp",
    PRODContainerApps = "PRODContainerApp"
}