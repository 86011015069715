import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import GlobalConfig from '../global-config';
import { AccessModulesAndAccessRightsGroup } from '../shared/module/AccessModulesAndAccessRightsGroup';
import { IdentityRole } from '../shared/module/IdentityRole';
import { PopulateRolesModel, RoleSearchModel } from '../shared/module/RoleSearchModel';
import { RoleViewModel } from '../shared/module/RoleViewModel';
import { RolesSelectedItem } from '../shared/module/SelectedItem';
import { FormFireApiService } from './formfire-api.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityRolesService {
  url = GlobalConfig.IDENTITY_SERVICE_HOST;

  constructor(private formFireApiService: FormFireApiService) { }

  // https://localhost:44391/identity/role
  GetAllRoles() {
    return this.formFireApiService.get<IdentityRole[]>(this.url + 'role/');
  }

  // https://localhost:44391/identity/role/?id=f46ccf1e-651e-42b4-bc63-0094dc59e76b
  GetRoleById(roleId: number) {
    return this.formFireApiService.get<IdentityRole>(this.url + 'role?id=' + roleId);
  }

  // https://localhost:44391/identity/role?name=superadmin&
  // roleTypeIds=37672832-22e5-4542-bd2a-5776e3b6dc94&roleTypeIds=9a0fce04-29e0-49ac-839e-88b333a59f0c&roleTypeIds=f3f66238-bf9c-483f-baf7-ae7cded1d9ab
  SearchRole(searchText: RoleSearchModel) {
    let roleTypeIds: string = '';
    searchText.roleTypes.forEach(rt => {
      roleTypeIds += '&roleTypeIds=' + rt.id;
    });
    const roleName = searchText.roleName ? '&name=' + searchText.roleName : '';
    return this.formFireApiService.get<IdentityRole[]>(this.url + 'role?includeInactive=' + searchText.inActive + roleName + roleTypeIds);
  }

  PopulateRoles(searchText: RoleSearchModel): Observable<any> {
    this.SearchRole(searchText).subscribe(data => {
      let rolesNamesList: PopulateRolesModel[] = [];
      const roles: IdentityRole[] = data.result;
      roles.forEach(r => {
        rolesNamesList.push({
          text: searchText.roleName,
          value: r.name,
          isActive: r.isActive
        })
      })
      return of(rolesNamesList);
    });
    return of([]);
  }

  // https://localhost:44391/identity/role?roleTypeIds=f3f66238-bf9c-483f-baf7-ae7cded1d9ab
  GetRolesByType(roleTypeId: number) {
    return this.formFireApiService.get<IdentityRole[]>(this.url + 'role?roleTypeIds=' + roleTypeId);
  }

  // https://localhost:44391/identity/role
  CreateRole(role: RoleViewModel) {
    return this.formFireApiService.post<number>(this.url + 'role/', role);
  }

  // https://localhost:44391/identity/role
  UpdateRole(role: RoleViewModel) {
    return this.formFireApiService.put<number>(this.url + 'role/', role);
  }

  // https://localhost:44391/identity/role/GetRoleAccessModulesAndRights?roleTypeId=f3f66238-bf9c-483f-baf7-ae7cded1d9ab
  GetAllAccessModules(roleTypeId: number) {
    return this.formFireApiService.get<AccessModulesAndAccessRightsGroup[]>(this.url + 'role/role-access-modules-and-rights?roleTypeId=' + roleTypeId);
  }

  // https://localhost:44391/identity/role/GetRoleTypes
  GetRolesTypes() {
    return this.formFireApiService.get<RolesSelectedItem[]>(this.url + 'role/role-types');
  }
}
