import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

// CustomPreloadingStrategy service used for preloading module

import { CustomPreloadingStrategy } from './services/custom-preloading-strategy.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard, IdentityAuthGuard } from './auth.guard';
import { ErrorComponent } from './shared/common/error/error.component';
import { AccessModulesEnum } from './shared/enums/AccessModulesEnum';
import { SystemSettingsComponent } from './modules/system-settings/system-settings.component';

// routing and lazyloading with prealoding declaration for all modules this is main routing module of app

const routes: Routes = [
  
  {
    path: 'validate-email',
    loadChildren:
      () => import('./modules/validate-email/validate-email.module').then(m => m.ValidateEmailModule),
    data: { preload: true },
  },
  {
    path: 'account-verification',
    loadChildren:
      () => import('./modules/account-verification/account-verification.module').then(m => m.AccountVerificationModule),
  },
  {
    path: 'admin-users',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.UserManageMent,
    },
  },

  {
    path: 'roles',
    loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.RoleManagement,
    },
  },

  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [IdentityAuthGuard]
  },

  {
    path: 'system-settings',
    loadChildren:
      () => import('./modules/system-settings/system-settings.module').then(m => m.SystemSettingsModule),
  },

  {
    path: 'sales-code',
    loadChildren: () => import('./modules/sales-code/sales-code.module').then(m => m.SalesCodeModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.SalesCodeManagement },
  },

  {
    path: 'programs',
    loadChildren:
      () => import('./modules/program-type/program-type.module').then(m => m.ProgramTypeModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.ProgramManagement },
  },
  {
    path: 'document-form-type',
    loadChildren:
      () => import('./modules/document-form-type/document-form-type.module').then(m => m.DocumentFormTypeModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.DocumentAndFormManagement },
  },

  {
    path: 'benefits-setting',
    loadChildren:
      () => import('./modules/benefits-setting/benefits-setting.module').then(m => m.BenefitsSettingModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: 'Benefit Settings' },
  },

  {
    path: 'coverage-lines',
    loadChildren:
      () => import('./modules/coverage-lines/coverage-lines.module').then(m => m.CoverageLinesModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.CoverageLineManagement },
  },


  {
    path: 'setup-password',
    loadChildren:
      () => import('./modules/setup-password/setup-password.module').then(m => m.SetupPasswordModule),
  },

  {
    path: 'setup-username',
    loadChildren:
      () => import('./modules/setup-username/setup-username.module').then(m => m.SetupUsernameModule),
  },

  {
    path: 'set-otp',
    loadChildren: () => import('./modules/set-otp/set-otp.module').then(m => m.SetOtpModule),
  },

  {
    path: 'carriers',
    loadChildren: () => import('./modules/carriers/carriers.module').then(m => m.CarriersModule),
    canActivate: [AuthGuard],
    data: {
      ExpectedModuleName: AccessModulesEnum.CarrierManagement,
      preload: true,
    },
  },

  {
    path: 'agencies',
    loadChildren: () => import('./modules/agencies/agencies.module').then(m => m.AgenciesModule),
    canActivate: [AuthGuard],
    data: {
      ExpectedModuleName: AccessModulesEnum.AgencyManagement,
      preload: true,
    },
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthGuard],
    data: {
      ExpectedModuleName: AccessModulesEnum.ProductManagement,
      preload: true,
    },
  },

  {
    path: 'healthcheck',
    loadChildren: () => import('./modules/health-check/health-check.module').then(m => m.HealthCheckModule),
    data: {
      preload: true
    },
  },
  {
    path: 'subscription-management',
    loadChildren:
      () => import('./modules/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.SubscriptionManagement,
    },
  },

  {
    path: 'reset-username-password',
    loadChildren:
      () => import('./modules/reset-username-password/reset-username-password.module').then(m => m.ResetUsernamePasswordModule),
  },

  {
    path: 'reset-username',
    loadChildren:
      () => import('./modules/reset-username/reset-username.module').then(m => m.ResetUsernameModule),
  },

  {
    path: 'reset-by-password',
    loadChildren:
      () => import('./modules/reset-by-password/reset-by-password.module').then(m => m.ResetByPasswordModule),
  },

  {
    path: 'set-security-questions',
    loadChildren:
      () => import('./modules/set-security-questions/set-security-questions.module').then(m => m.SetSecurityQuestionsModule),
  },

  {
    path: 'security-questions',
    loadChildren:
      () => import('./modules/security-questions/security-questions.module').then(m => m.SecurityQuestionsModule),
  },

  {
    path: 'set-two-factor-authentication',
    loadChildren:
      () => import('./modules/set-two-factor-authentication/set-two-factor-authentication.module').then(m => m.SetTwoFactorAuthenticationModule),
  },

  {
    path: 'language',
    loadChildren: () => import('./modules/language/language.module').then(m => m.LanguageModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.MasterManagement,
    },
  },
  {
    path: 'rating-type',
    loadChildren: () => import('./modules/rating-type/rating-type.module').then(m => m.RatingTypeModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.MasterManagement,
    },
  },
  {
    path: 'address-type',
    loadChildren:
      () => import('./modules/address-type/address-type.module').then(m => m.AddressTypeModule),
  },
  {
    path: 'state-level-composites-factor',
    loadChildren:
      () => import('./modules/state-level-composites/state-level-composites.module').then(m => m.StateLevelCompositesModule),
    canActivate: [AuthGuard],
    data: {
      preload: true,
      ExpectedModuleName: AccessModulesEnum.StateLevelCompositeFactor,
    },
  },
  {
    path: 'tier-structure',
    loadChildren:
      () => import('./modules/tier-structure/tier-structure.module').then(m => m.TierStructureModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.MasterManagement },
  },

  {
    path: 'county',
    loadChildren: () => import('./modules/county/county.module').then(m => m.CountyModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.MasterManagement },
  },
  {
    path: 'zips',
    loadChildren: () => import('./modules/zips/zips.module').then(m => m.ZipsModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.MasterManagement },
  },

  {
    path: 'set-new-password',
    loadChildren:
      () => import('./modules/set-temporary-password/set-temporary-password.module').then(m => m.SetTemporaryPasswordModule),
  },

  {
    path: 'coverage-tier',
    loadChildren:
      () => import('./modules/coverage-tier/coverage-tier.module').then(m => m.CoverageTierModule),
  },

  {
    path: 'formfire-census',
    loadChildren:
      () => import('./modules/formfire-census/formfire-census.module').then(m => m.FormfireCensusModule),
    canActivate: [AuthGuard],
    data: { ExpectedModuleName: AccessModulesEnum.MasterManagement },
  },

  {
    path: 'back',
    loadChildren: () => import('./modules/proxy-back/proxy-back.module').then(m => m.ProxyBackModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    redirectTo: '/profile',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  declarations: [ErrorComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(
      routes,

      {
        useHash: true,
        initialNavigation: 'enabledNonBlocking',
        preloadingStrategy: CustomPreloadingStrategy,
      }
    ),
  ],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {}
