import { Component, OnInit, Injectable } from '@angular/core';
import { SharedServices } from '../services/shared.service';
import { ApiService } from '../services/api.service';
import { RoleModel } from './module/RoleModel';
import { RoleTypeEnum } from './enums/RoleTypeEnum';
import { IdentityRolesService } from '../services/identity-roles.service';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { IdentityUsersService } from '../services/identity-users.service';
import { FeatureFlagService } from '../services/featureFlag/feature-flag-service';

@Injectable({
  providedIn: 'root'
})

export class MasterAPICall implements OnInit {
  public accessiableModules: string[];
  public userAccessRights: string[];
  roleList: RoleModel[];
  roleTypes: [];
  token: string;
  constructor(
    private sharedService: SharedServices, private apiService: ApiService, private identityUserService: IdentityUsersService,
    private identityRolesService: IdentityRolesService, private authStorage: OAuthStorage, private featureFlagService: FeatureFlagService) { }
  public ngOnInit() {
  }

  appInitialCall() {
    return new Promise<void>((resolve, reject) => {
      // if (this.authStorage.getItem('access_token')) {
      //   this.CallMasterAPI();
      // }
      resolve();
    });
  }

  GetRoleList() {
    try {
      this.sharedService.GetRoleModel = [];
      this.identityRolesService.GetRolesByType(this.sharedService.GetFormfireUserRoleTypeId).subscribe(data => {
        let rolesModel: RoleModel[] = [];
        data.result.forEach(role => {
          rolesModel.push({ roleId: role.id, roleName: role.name });
        });
        this.sharedService.GetRoleModel = rolesModel;
        this.sharedService.masterApiResolvedCount++;
      },
        error => {
          this.sharedService.masterApiResolvedCount++;
        }
      );
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetRoleList. ' + error);
    }
  }

  // get Role Types
  GetRoleTypes() {
    this.sharedService.GetRoleTypesModel = [];
    this.identityRolesService.GetRolesTypes().subscribe(data => {
      this.sharedService.GetRoleTypesModel = data.result;
      this.sharedService.GetFormfireUserRoleTypeId = data.result.find(rt => rt.itemName === RoleTypeEnum.FormFireUsers).id;
      this.sharedService.masterApiResolvedCount++;
      this.GetRoleList();
    }, error => {
      this.sharedService.masterApiResolvedCount++;
    });
  }
  // to load dynamic data on coverage line page
  loadCoverageLineData() {
    try {
      this.sharedService.GetCoverageSettings = [];
      this.sharedService.GetRelationTypes = [];
      this.sharedService.getCoverageLineData().subscribe(data => {
        this.sharedService.GetCoverageSettings = data.coverageSettings;
        this.sharedService.GetRelationTypes = data.relationTypes;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Exception in: master-api.component.ts - In method: GetDocumentAndFormList. ' + error);
    }
  }

  GetRelationTypeList() { // need to remove
    try {
      this.sharedService.GetRelationTypeList().subscribe(data => { // fetch from RelationTypes
        if (data !== null && data !== undefined) {
          this.sharedService.GetCoverageTiersItemList = [];
          for (let i = 0, len = data.length; i < len; i++) {
            this.sharedService.GetCoverageTiersItemList.push({ id: data[i].relationTypeId, itemName: data[i].relationTypeName });
          }
        }
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetRelationTypeList. ' + error);
    }
  }

  /*gets all states master data */
  GetStates() {
    try {
      this.sharedService.GetStatesModel = [];
      this.sharedService.GetStates().subscribe(data => {
        if (data != null && data !== undefined) {
          this.sharedService.GetStatesModel = data;
        }
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetStates. ' + error);
    }
  }
  /*gets all address types master data */
  GetAddressTypes() {
    try {
      this.sharedService.GetAddressTypesModel = [];
      this.sharedService.GetAddressTypes().subscribe(data => {
        if (data !== null && data !== undefined) {
          this.sharedService.GetAddressTypesModel = data;
        }
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetAddressTypes. ' + error);
    }
  }

  GetRateRoundingOptions() {
    const that = this;
    try {
      this.sharedService.GetRateRoundingOptionsModel = [];
      this.sharedService.GetRateRoundingOptions().subscribe(data => {
        this.sharedService.GetRateRoundingOptionsModel = data;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetRateRoundingOptions. ' + error);
    }
  }

  GetFormCategory() {
    const that = this;
    try {
      this.sharedService.GetFormCategoryModel = [];
      this.sharedService.GetFormCategory().subscribe(data => {
        this.sharedService.GetFormCategoryModel = data;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetFormCategory. ' + error);
    }
  }


  // Get form status
  GetFormStatuses() {
    const that = this;
    try {
      this.sharedService.GetFormStatusesModel = [];
      this.sharedService.GetFormStatuses().subscribe(data => {
        this.sharedService.GetFormStatusesModel = data;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetFormStatuses. ' + error);
    }
  }



  // Get languages
  GetLanguages() {
    const that = this;
    this.sharedService.GetLanguagesModel = [];
    try {
      this.sharedService.GetLanguages().subscribe(data => {
        this.sharedService.GetLanguagesModel = [];
        if (data !== null && data !== undefined) {
          for (let i = 0, len = data.length; i < len; i++) {
            this.sharedService.GetLanguagesModel.push({ id: data[i].id, itemName: data[i].itemName, isChecked: false });
          }
        }
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetLanguages. ' + error);
    }
  }

  // get extra info
  GetExtraInfoSections() {
    const that = this;
    try {
      this.sharedService.GetExtraInfoSections().subscribe(data => {
        this.sharedService.GetExtraInfoSectionsModel = [];
        this.sharedService.GetExtraInfoSectionsModel = data;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetExtraInfoSections. ' + error);
    }
  }

  GetCoverageLines() {
    try {
      this.sharedService.getCoverageLinesMasterModel = [];
      this.sharedService.getCoverageLinesMaster().subscribe(response => {
        for (let i = 0, len = response.length; i < len; i++) {
          this.sharedService.getCoverageLinesMasterModel.push({ id: response[i].coverageLineId, itemName: response[i].coverageLineName });
        }
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetCoverageLines. ' + error);
    }
  }

  // Get products master data
  GetProductTypes() {
    try {
      this.sharedService.getProductTypesModel = [];
      this.sharedService.getProductTypes().subscribe(response => {
        this.sharedService.getProductTypesModel = response;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetProductTypes. ' + error);
    }
  }

  // Get carriers master data
  GetCarriers() {
    try {
      this.sharedService.getCarriersMasterModel = [];
      this.sharedService.getCarriersMaster().subscribe(response => {
        this.sharedService.getCarriersMasterModel = response;
        this.sharedService.masterApiResolvedCount++;
      }, error => {
        this.sharedService.masterApiResolvedCount++;
      });
    } catch (error) {
      this.sharedService.masterApiResolvedCount++;
      this.apiService.LogError('Error in master-api.component.ts in Method: GetCarriers. ' + error);
    }
  }


  getServiceAreas() {
    this.sharedService.getServiceAreasModel = [];
    this.sharedService.getServiceAreas().subscribe(data => {
      this.sharedService.getServiceAreasModel = data;
      this.sharedService.masterApiResolvedCount++;
    }, error => {
      this.sharedService.masterApiResolvedCount++;
    });
  }

  GetUserDetails(userId: number) {
    this.identityUserService.GetUserDetails(userId).subscribe(data => {
      this.sharedService.GetLoggedUser = data.result;
    }, error => {
        console.error(error.error.errorMessage);
    });
  }

  CallMasterAPI(userId: number) {
    if (!this.sharedService.masterApiResolved) {
      this.GetRoleTypes();
      this.loadCoverageLineData();
      this.GetRelationTypeList();
      this.GetStates();
      this.GetAddressTypes();
      this.GetRateRoundingOptions();
      this.GetFormCategory();
      this.GetFormStatuses();
      this.GetLanguages();
      this.GetExtraInfoSections();
      this.GetCoverageLines();
      this.GetProductTypes();
      this.GetCarriers();
      this.getServiceAreas();
      this.GetUserDetails(userId);
      this.GetFeatureFlagStatuses();
      this.sharedService.masterApiResolved = true;
    }
  }

  GetFeatureFlagStatuses() {
    try {
      this.featureFlagService.prepareObject();
    }
    catch (error) {
      this.sharedService.masterApiResolvedCount++;
    }
  }
}






