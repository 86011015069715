import { EnvironmentEnum } from "./environmentEnum";

export default class GlobalConfig {
  public static get APP_INSIGHTS_KEY(): string {
    return "60f3a962-0f6f-497a-b374-a5927913061c";
  }

  public static get PRODUCTION(): boolean {
    if (GlobalConfig.Environment === EnvironmentEnum.PROD  || GlobalConfig.Environment === EnvironmentEnum.PRODContainerApps) {
      return true;
    } else {
      return false;
    }
  }

  public static get DOCKER_IMAGE_TAG(): string {
    if (GlobalConfig.Environment === EnvironmentEnum.CI  || GlobalConfig.Environment === EnvironmentEnum.CIContainerApps) {
      return "SOMETHING";
    }
    return "NO_TAG";
  }

  public static get FORMFIRE_LEGACY_ADMIN_HOST(): string {
    switch (GlobalConfig.Environment) {
      case EnvironmentEnum.LOCAL:
        return "http://localhost/";
      case EnvironmentEnum.CI:
        return "https://ci-web.formfire.local/Admin/";
      case EnvironmentEnum.QA:
        return "https://qa-web.formfire.local/Admin/";
      case EnvironmentEnum.UAT:
        return "https://uat1.formfire.net/admin/";
      case EnvironmentEnum.PROD:
        return "https://www.formfire.com/admin/";
      case EnvironmentEnum.CIContainerApps:
        return "https://ci-formfire.quoteplus.io/Admin/";
      case EnvironmentEnum.QAContainerApps:
        return "https://qa-formfire.quoteplus.io/Admin/";
      case EnvironmentEnum.UATContainerApps:
        return "https://uat-formfire.quoteplus.io/admin/";
      case EnvironmentEnum.PRODContainerApps:
        return "https://www.formfire.com/admin/";
    }
  }

  public static get API_HOST(): string {
    switch (GlobalConfig.Environment) {
      case EnvironmentEnum.LOCAL:
        return "https://ci-api.quoteplus.io";
      case EnvironmentEnum.CI:
        return "https://api.formfire.dev";
      case EnvironmentEnum.QA:
        return "https://qa2.formfire.org";
      case EnvironmentEnum.UAT:
        return "https://uat2.formfire.net";
      case EnvironmentEnum.PROD:
        return "https://prodsrv.formfire.com";
      case EnvironmentEnum.CIContainerApps:
        return "https://ci-api.quoteplus.io";
      case EnvironmentEnum.QAContainerApps:
        return "https://qa-api.quoteplus.io";
      case EnvironmentEnum.UATContainerApps:
        return "https://uat-api.quoteplus.io";
      case EnvironmentEnum.PRODContainerApps:
        return "https://api.quoteplus.com";
    }
  }

  public static get AUTH_HOST(): string {
    switch (GlobalConfig.Environment) {
      case EnvironmentEnum.LOCAL:
        return "https://ci-auth.quoteplus.io";
      case EnvironmentEnum.CI:
        return "https://auth.formfire.dev";
      case EnvironmentEnum.QA:
        return "https://qa2.formfire.org";
      case EnvironmentEnum.UAT:
        return "https://uat2.formfire.net";
      case EnvironmentEnum.PROD:
        return "https://prodsrv.formfire.com";
      case EnvironmentEnum.CIContainerApps:
        return "https://ci-auth.quoteplus.io";
      case EnvironmentEnum.QAContainerApps:
        return "https://qa-auth.quoteplus.io";
      case EnvironmentEnum.UATContainerApps:
        return "https://uat-auth.quoteplus.io";
      case EnvironmentEnum.PRODContainerApps:
        return "https://auth.quoteplus.com";
    }
  }

  public static get AGENCY_WEBAPP_URL(): string {
    switch (GlobalConfig.Environment) {
      case EnvironmentEnum.LOCAL:
        return "https://ci-app.quoteplus.io";
      case EnvironmentEnum.CI:
        return "https://manage.formfire.dev";
      case EnvironmentEnum.QA:
        return "https://manage.formfire.org";
      case EnvironmentEnum.UAT:
        return "https://manage.formfire.net";
      case EnvironmentEnum.PROD:
        return "https://manage.formfire.com";
      case EnvironmentEnum.CIContainerApps:
        return "https://ci-app.quoteplus.io";
      case EnvironmentEnum.QAContainerApps:
        return "https://qa-app.quoteplus.io";
      case EnvironmentEnum.UATContainerApps:
        return "https://uat-app.quoteplus.io";
      case EnvironmentEnum.PRODContainerApps:
        return "https://app.quoteplus.com";
    }
  }

  public static get USERS_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/users/`;
  }

  public static get ADMIN_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/admin/`;
  }

  public static get PRODUCT_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/products/`;
  }

  public static get AGENCIES_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/agencies/`;
  }

  public static get PROGRAMS_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/programs/`;
  }

  public static get SUBSCRIPTIONS_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/subscriptions/`;
  }

  public static get CENSUSES_SERVICE_HOST(): string {
    return `${GlobalConfig.API_HOST}/censuses/`;
  }

  public static get IDENTITY_SERVICE_HOST(): string {
    return `${GlobalConfig.AUTH_HOST}/identity/`;
  }

  public static get IS_OAUTH_ENABLED(): boolean {
    switch (GlobalConfig.Environment) {
      case EnvironmentEnum.LOCAL:
        return true;
      case EnvironmentEnum.CI:
        return true;
      case EnvironmentEnum.QA:
        return true;
      case EnvironmentEnum.UAT:
        return true;
      case EnvironmentEnum.PROD:
        return true;
      case EnvironmentEnum.CIContainerApps:
        return true;
      case EnvironmentEnum.QAContainerApps:
        return true;
      case EnvironmentEnum.UATContainerApps:
        return true;
      case EnvironmentEnum.PRODContainerApps:
        return true;
    }
  }

  public static get Environment(): EnvironmentEnum {
    const host = window.location.host.toLowerCase();
    if (host) {
      if (
        host.includes("sysadmin.formfire.dev") ||
        host.includes("sysadmin.formfire.dev")
      ) {
        return EnvironmentEnum.CI;
      }
      if (host.includes("sysadmin.formfire.org")) {
        return EnvironmentEnum.QA;
      }
      if (host.includes("sysadmin.formfire.net")) {
        return EnvironmentEnum.UAT;
      }
      if (host.includes("sysadmin.formfire.com")) {
        return EnvironmentEnum.PROD;
      }
      if (host.includes("ci-sysadmin.quoteplus.io")) {
        return EnvironmentEnum.CIContainerApps;
      }
      if (host.includes("qa-sysadmin.quoteplus.io")) {
        return EnvironmentEnum.QAContainerApps;
      }
      if (host.includes("uat-sysadmin.quoteplus.io")) {
        return EnvironmentEnum.UATContainerApps;
      }
      if (host.includes("sysadmin.quoteplus.com")) {
        return EnvironmentEnum.PRODContainerApps;
      }
    }
    return EnvironmentEnum.LOCAL;
  }
}
