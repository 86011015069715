import { Injectable } from "@angular/core";
import GlobalConfig from "src/app/global-config";
import { ApiService, HttpReqMethod } from "../api.service";
import { IfeatureFlag, IfeatureFlagConfig } from "./feature-flag-interfaces";
import { Observable, Subject } from "rxjs";

export enum FeatureFlagEnum {
    AgencySelectComponent = "agencySelectComponent",
    MaterialComponents = "materialcomponentsadmin",
    Branding = "branding",
    SalesCodeUpgrade = "SalesCodeUpgrade"
  }

  @Injectable({
    providedIn: "root",
  })
  export class FeatureFlagService {
    private featureFlagUrl =
      GlobalConfig.ADMIN_SERVICE_HOST + "api/configuration";
    featureFlagConfig: IfeatureFlagConfig = null;
    featureFlagConfigSubject: Subject<IfeatureFlagConfig> = new Subject()

    constructor(private apiService: ApiService) { }

    featureOff(featureName: FeatureFlagEnum) {
      return !this.featureOn(featureName);
    }

    featureOn(featureName: FeatureFlagEnum) {
      return this.isFeatureOn(this.featureFlagConfig.featureFlags, featureName);
    }

    getFeatureFlags() {
      if (this.featureFlagConfig) {
        return new Observable<IfeatureFlagConfig>(s => {
          s.next(this.featureFlagConfig)
          s.complete()
        })
      }

      return this.apiService.Request<IfeatureFlagConfig>(
        this.featureFlagUrl,
        HttpReqMethod.GET.toString()
      );
    }

    isFeatureOn(featureFlags: IfeatureFlag[], featureName: FeatureFlagEnum) {
      let featureOn = false;
      featureFlags.forEach((featureFlag) => {
        if (
          featureFlag.name.toLowerCase() === featureName.toLowerCase() &&
          featureFlag.isActive
        ) {
          featureOn = true;
        }
      });
      return featureOn;
    }

    prepareObject() {
      this.getFeatureFlags().subscribe(featureFlags => {
        this.featureFlagConfig = featureFlags
        this.featureFlagConfigSubject.next(this.featureFlagConfig)
      }, e => {
        throw e;
      })
    }
  }
