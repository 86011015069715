import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpReqMethod, ApiService } from '../services/api.service';
import { RoleModel } from '../shared/module/RoleModel';
import { CoverageRatesViewModel } from '../shared/module/CoverageLines/CoverageRatesViewModel';
import { CoverageSettingViewModel } from '../shared/module/CoverageLines/CoverageSettingViewModel';
import { CoverageTiersModel } from '../shared/module/CoverageLines/CoverageTiersModel';
import { UserModel } from '../shared/module/UserModel';
import { RoleTypeEnum } from '../shared/enums/RoleTypeEnum';
import { OTPReturnModel } from '../shared/module/AccountSetup/OTPReturnModel';
import { StateModel } from '../shared/module/Carriers/StateModel';
import { RolesSelectedItem, SelectedItem } from '../shared/module/SelectedItem';
import { RelationTypesModel } from '../shared/module/CoverageLines/RelationTypesModel';
import * as jwt_decode from 'jwt-decode';
import { AppSettings } from '../AppSettings';
import { SearchFilterComponent } from '../shared/search-filter-component';
import GlobalConfig from '../global-config';
import { IdentityUser } from '../shared/module/IdentityUser';
import { AgencyComponent } from '../shared/agency/agency.component';

@Injectable({
  providedIn: 'root',
})
export class SharedServices {
  ffUSURL42B0B5AFABAE = GlobalConfig.USERS_SERVICE_HOST;
  ffANSURL42B0B5ABAE = GlobalConfig.ADMIN_SERVICE_HOST;
  ffAGCYSURLB3AFE15F240C = GlobalConfig.AGENCIES_SERVICE_HOST;
  Avatar: string = null;
  roleList: RoleModel[];
  roleTypes: RolesSelectedItem[];
  coverageRates: CoverageRatesViewModel[];
  coverageSettings: CoverageSettingViewModel[];
  coverageTiers: CoverageTiersModel[];
  relationTypes: RelationTypesModel[];
  CoverageTiersItemList: any[] = [];
  states: StateModel[] = [];
  addressTypes: any[] = [];
  rateRoundingOptionsMasterList: SelectedItem[] = [];
  FormCategoryList: SelectedItem[] = [];
  formStatus: SelectedItem[];
  languageDropdownList: SelectedItem[] = [];
  extraInfoList: SelectedItem[];

  coverageLinesList: any[] = [];
  productTypes: any[] = [];
  carriers: any[] = [];
  serviceAreas: any[] = [];
  isTempPasswordSet: boolean = null;
  masterApiResolvedCount = 0;
  masterApiResolved = false;
  DKCEBF1C4FB: string;
  LoginExpirationTime: number;
  roleName: string;
  userId: string;
  identityUserId: number;
  appSettings: AppSettings = new AppSettings();
  isProxyLogin = false;
  loggedUser: IdentityUser;
  agencyComponent: AgencyComponent;

  formfireUserRoleTypeId: number;
  constructor(
    private apiService: ApiService,
    private searchFilterComponent: SearchFilterComponent
  ) {}

  get GetFormfireUserRoleTypeId(): number {
    return this.formfireUserRoleTypeId;
  }

  set GetFormfireUserRoleTypeId(val: number) {
    this.formfireUserRoleTypeId = val;
  }

  get AgencyComponent(): AgencyComponent {
    return this.agencyComponent;
  }

  set AgencyComponent(val: AgencyComponent) {
    this.agencyComponent = val;
  }

  get GetAvatar(): string {
    return this.Avatar;
  }

  set GetAvatar(val: string) {
    this.Avatar = val;
  }
  // fetch Roles
  // TODO: Review this implementation -
  // gets from user services all the active roles (excluding the admin role) by roleTypeName
  PL7D81C33CRLEAC44DA5AC25FF(roleTypeName: string): Observable<RoleModel[]> {
    return this.apiService.Request<any>(
      this.ffUSURL42B0B5AFABAE +
        'api/user/getRoleLists?roleTypeName=' +
        roleTypeName,
      HttpReqMethod.GET.toString()
    );
  }
  // get user profile details
  PSCCB5C62UPDF354E61PWD90A(): Observable<UserModel> {
    const roleType = RoleTypeEnum.FormFireUsers.toString();
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST + 'api/user/GetUserProfileDetails',
      HttpReqMethod.GET.toString()
    );
  }

  get GetRoleModel(): RoleModel[] {
    return this.roleList;
  }
  set GetRoleModel(val: RoleModel[]) {
    this.roleList = val;
  }

  // while deactivating broker check wether broker is primary associted with employer
  CheckIsBrokerPrimary(brokerId: string) {
    return this.apiService.Request<any>(
      GlobalConfig.AGENCIES_SERVICE_HOST +
        'api/agencyBrokers/IsPrimaryBroker?brokerId=' +
        brokerId,
      HttpReqMethod.GET.toString()
    );
  }
  // activate deactivate agency user
  ACT1243OR46D2DECTU3B68E9B2(model: object) {
    return this.apiService.Request<any>(
      GlobalConfig.AGENCIES_SERVICE_HOST +
        'api/agencyBrokers/ActivateDeactiveBroker',
      HttpReqMethod.POST.toString(),
      model
    );
  }
  // delete agency user
  DEL1243OR46D2DECTU3B68E9B3(model: object) {
    return this.apiService.Request<any>(
      GlobalConfig.AGENCIES_SERVICE_HOST + 'api/agencyBrokers/DeleteBroker',
      HttpReqMethod.POST.toString(),
      model
    );
  }

  // TODO: Review this implementation
  getRoleType(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/role' + '/getRoleTypes',
      HttpReqMethod.GET.toString()
    );
  }

  get GetRoleTypesModel(): RolesSelectedItem[] {
    return this.roleTypes;
  }
  set GetRoleTypesModel(val: RolesSelectedItem[]) {
    this.roleTypes = val;
  }

  getCoverageLineData(): Observable<any> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/CoverageLines' + '/GetCoverageLineData',
      HttpReqMethod.GET.toString()
    );
  }
  get GetCoverageRates(): CoverageRatesViewModel[] {
    return this.coverageRates;
  }
  set GetCoverageRates(val: CoverageRatesViewModel[]) {
    this.coverageRates = val;
  }

  get GetCoverageSettings(): CoverageSettingViewModel[] {
    return this.coverageSettings;
  }
  set GetCoverageSettings(val: CoverageSettingViewModel[]) {
    this.coverageSettings = val;
  }
  get GetCoverageTiersModel(): CoverageTiersModel[] {
    // need to change
    return this.coverageTiers;
  }
  set GetCoverageTiersModel(val: CoverageTiersModel[]) {
    // need to  change
    this.coverageTiers = val;
  }
  get GetRelationTypes(): RelationTypesModel[] {
    return this.relationTypes;
  }
  set GetRelationTypes(val: RelationTypesModel[]) {
    this.relationTypes = val;
  }
  
  get GetLoggedUser(): IdentityUser {
    return this.loggedUser;
  }
  set GetLoggedUser(val: IdentityUser) {
    this.loggedUser = val;
  }

  GetCoverageTiers(): Observable<any[]> {
    // need to change fetch from RelationTypes
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/CoverageLines' + '/GetCoverageTiers',
      HttpReqMethod.GET.toString()
    );
  }
  GetRelationTypeList(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/CoverageLines' + '/GetRelationTypes',
      HttpReqMethod.GET.toString()
    );
  }

  get GetCoverageTiersItemList(): any[] {
    return this.CoverageTiersItemList;
  }
  set GetCoverageTiersItemList(val: any[]) {
    this.CoverageTiersItemList = val;
  }
  GetStates(): Observable<StateModel[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/Data/' + 'GetStates',
      HttpReqMethod.GET.toString()
    );
  }
  get GetStatesModel(): StateModel[] {
    return this.states;
  }
  set GetStatesModel(val: StateModel[]) {
    this.states = val;
  }
  GetAddressTypes(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/AddressType/' + 'GetAddressTypesList',
      HttpReqMethod.GET.toString()
    );
  }
  get GetAddressTypesModel(): any[] {
    return this.addressTypes;
  }
  set GetAddressTypesModel(val: any[]) {
    this.addressTypes = val;
  }
  GetRateRoundingOptions(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/Data/' + 'GetRateRoundingOptions',
      HttpReqMethod.GET.toString()
    );
  }
  get GetRateRoundingOptionsModel(): SelectedItem[] {
    return this.rateRoundingOptionsMasterList;
  }
  set GetRateRoundingOptionsModel(val: SelectedItem[]) {
    this.rateRoundingOptionsMasterList = val;
  }
  GetFormCategory(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/carriersForms/' + 'GetFormCategory',
      HttpReqMethod.GET.toString()
    );
  }

  get GetFormCategoryModel(): SelectedItem[] {
    return this.FormCategoryList;
  }
  set GetFormCategoryModel(val: SelectedItem[]) {
    this.FormCategoryList = val;
  }

  GetFormStatuses(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/carriersForms/' + 'GetFormStatuses',
      HttpReqMethod.GET.toString()
    );
  }

  get GetFormStatusesModel(): SelectedItem[] {
    return this.formStatus;
  }
  set GetFormStatusesModel(val: SelectedItem[]) {
    this.formStatus = val;
  }
  GetLanguages(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/language/' + 'GetLanguagesMaster',
      HttpReqMethod.GET.toString()
    );
  }

  get GetLanguagesModel(): SelectedItem[] {
    return this.languageDropdownList;
  }
  set GetLanguagesModel(val: SelectedItem[]) {
    this.languageDropdownList = val;
  }
  GetExtraInfoSections(): Observable<SelectedItem[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/carriersForms/' + 'GetExtraInfoSections',
      HttpReqMethod.GET.toString()
    );
  }

  get GetExtraInfoSectionsModel(): SelectedItem[] {
    return this.extraInfoList;
  }
  set GetExtraInfoSectionsModel(val: SelectedItem[]) {
    this.extraInfoList = val;
  }
  getCoverageLinesMaster(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE +
        'api/CoverageLines' +
        '/GetCoverageLinesDetails',
      HttpReqMethod.GET.toString()
    );
  }

  get getCoverageLinesMasterModel(): any[] {
    return this.coverageLinesList;
  }
  set getCoverageLinesMasterModel(val: any[]) {
    this.coverageLinesList = val;
  }
  getCarriersMaster(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/carriers' + '/GetCarriersMaster',
      HttpReqMethod.GET.toString()
    );
  }
  get getCarriersMasterModel(): any[] {
    return this.carriers;
  }
  set getCarriersMasterModel(val: any[]) {
    this.carriers = val;
  }
  getProductTypes(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/products' + '/getProductTypes',
      HttpReqMethod.GET.toString()
    );
  }
  get getProductTypesModel(): any[] {
    return this.productTypes;
  }
  set getProductTypesModel(val: any[]) {
    this.productTypes = val;
  }
  getServiceAreas(): Observable<any[]> {
    return this.apiService.Request<any>(
      this.ffANSURL42B0B5ABAE + 'api/ServiceArea' + '/getServiceAreas',
      HttpReqMethod.GET.toString()
    );
  }
  get getServiceAreasModel(): any[] {
    return this.serviceAreas;
  }
  set getServiceAreasModel(val: any[]) {
    this.serviceAreas = val;
  }
  GetUserHasTempPassword(userId: string): Observable<any> {
    return this.apiService.Request<any>(
      this.ffUSURL42B0B5AFABAE +
        'api/forgotPassword/IsUserHaveTempPassword?param=' +
        userId,
      HttpReqMethod.GET.toString()
    );
  }

  GetOTPTimeRemaining(
    expirationTime: number,
    otpExpirationTime: string
  ): OTPReturnModel {
    const optReturnModel = new OTPReturnModel();
    const otpExpirationDateTime = new Date(otpExpirationTime);

    otpExpirationDateTime.setMinutes(
      otpExpirationDateTime.getMinutes() + expirationTime
    );
    const currentDateTime = new Date();
    const milisecondsDiff =
      otpExpirationDateTime.getTime() - currentDateTime.getTime();
    optReturnModel.otpTimeRemainingInMiniute = milisecondsDiff / 1000 / 60;
    const updatedSecond = (milisecondsDiff / 1000) % 60;
    optReturnModel.otpTimeRemainingInSecond = this.convertIntoTime(
      updatedSecond
    );
    if (optReturnModel.otpTimeRemainingInSecond === '00') {
      optReturnModel.otpTime =
        Math.ceil(optReturnModel.otpTimeRemainingInMiniute) +
        ':' +
        optReturnModel.otpTimeRemainingInSecond;
    } else {
      optReturnModel.otpTime =
        Math.ceil(optReturnModel.otpTimeRemainingInMiniute) -
        1 +
        ':' +
        optReturnModel.otpTimeRemainingInSecond;
    }
    return optReturnModel;
  }

  convertIntoTime(second) {
    const updatedSecond = Math.ceil(second);
    let secondString = String(updatedSecond);
    switch (updatedSecond) {
      case 1:
        secondString = '01';
        break;
      case 2:
        secondString = '02';
        break;
      case 3:
        secondString = '03';
        break;
      case 4:
        secondString = '04';
        break;
      case 5:
        secondString = '05';
        break;
      case 6:
        secondString = '06';
        break;
      case 7:
        secondString = '07';
        break;
      case 8:
        secondString = '08';
        break;
      case 9:
        secondString = '09';
        break;
      case 60:
        secondString = '00';
        break;
    }
    return secondString;
  }

  get GetRoleName(): string {
    if (!this.roleName) {
      this.roleName = sessionStorage.getItem('roleName');
    }
    return this.roleName;
  }
  set GetRoleName(val: string) {
    sessionStorage.setItem('roleName', val);
    this.roleName = val;
  }

  SetLoggedInUserRole(token) {
    let decoded;
    if (token !== undefined || token !== null) {
      decoded = jwt_decode(token);
      this.GetRoleName = decoded.RoleName;
      this.GetLoggedInUserId = decoded[this.appSettings.userIdIdentifier];
    }
  }
  get GetLoggedInUserId(): string {
    if (!this.userId) {
      this.userId = sessionStorage.getItem('userId');
    }
    return this.userId;
  }
  set GetLoggedInUserId(val: string) {
    sessionStorage.setItem('userId', val);
    this.userId = val;
  }

  SetLoggedInIdentityUserId(userId: number) {
    this.GetLoggedInIdentityUserId = userId;
  }
  get GetLoggedInIdentityUserId(): number {
    return this.identityUserId;
  }
  set GetLoggedInIdentityUserId(val: number) {
    this.identityUserId = val;
  }

  ClearGlobalData() {
    // Reset Coverage Line search Filter State
    this.searchFilterComponent.ResetCoverageSearchModelState();
    // Reset Agencies search Filter State
    this.searchFilterComponent.ResetAgenciesSearchModelState();
    // Reset Products search Filter State
    this.searchFilterComponent.ResetProductsSearchModelState();
    // Reset General Agencies search Filter State
    this.searchFilterComponent.ResetGeneralAgenciesSearchModelState();
    // Reset Carriers search Filter State
    this.searchFilterComponent.ResetCarriersSearchModelState();
    // Reset Form Fire Users search Filter State
    this.searchFilterComponent.ResetFormFireUsersSearchModelState();
    // Reset Agencies Association Broker search Filter State
    this.searchFilterComponent.ResetBrokerSearchModelState();
    // Reset General Agencies Association Broker search Filter State
    this.searchFilterComponent.ResetGAABrokerSearchModelState();
    // Reset Role search Filter State
    this.searchFilterComponent.ResetRoleSearchModelState();
    // Reset Program Type search filter model
    this.searchFilterComponent.ResetProgramTypeSearchModelState();
    // Reset Sales Code search filter model
    this.searchFilterComponent.ResetSalesCodeSearchModelState();
    // Reset Document and Form Type search filter model
    this.searchFilterComponent.ResetDocumentFormTypeSearchModelState();

    // Reset Carriers Product Portfolio search filter model
    this.searchFilterComponent.ResetCarriersProductSearchModelState();
    // Reset Subscription Search filter model
    this.searchFilterComponent.ResetSubscriptionsSearchModelState();
    // Reset county List search filter model
    this.searchFilterComponent.ResetCountyFilterModelState();
    // Reset Carriers Census Types List search filter model
    this.searchFilterComponent.ResetCensusSearchModelState();
    // Reset Carriers Composite Types List search filter model
    this.searchFilterComponent.ResetCompositeSearchModelState();
    // Reset zipcode List search filter model
    this.searchFilterComponent.ResetZipCodeFilterModelState();
    // Reset Tier tructure search filter model
    this.searchFilterComponent.ResetTierStructureSearchModelState();

    // Reset state level composite factor search filter model
    this.searchFilterComponent.ResetStateCompositeSearchModelState();
    /* End here */
    // Reset state level Age Banded search filter model
    this.searchFilterComponent.ResetProductAgeBandedSearchModelState();
    // Reset Product Portfolio search Filter State
    this.searchFilterComponent.ResetPortfolioCompositeSearchModelState();
    // Reset formfire census search Filter State
    this.searchFilterComponent.ResetFormFireCensusSearchModelState();
    // Reset Carriers Document Types List search filter model
    this.searchFilterComponent.ResetCarrierDocumentListSearchModelState();
    this.GetLoggedInUserId = null;
    this.GetRoleName = null;
  }
  get IsProxyLogin(): boolean {
    return this.isProxyLogin;
  }
  set IsProxyLogin(val: boolean) {
    this.isProxyLogin = val;
  }
}
