import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { filter, map } from 'rxjs/operators';
import { AuthConfigService } from './auth-config.service';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { IdentityUsersService } from '../services/identity-users.service';
import { SharedServices } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenClaims;
  expireTokenTime;
  isDiscoveryDocumentLoaded = false;

  private hasReceivedToken = new BehaviorSubject<boolean>(false);
  public hasReceivedToken$ = this.hasReceivedToken.asObservable();

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
    this.isAuthenticated$,
    this.isDoneLoading$
  ).pipe(map(values => values.every(b => b)));

  errorCodes = ['invalid_grant', 'interaction_required', 'login_required', 'account_selection_required', 'consent_required'];
  constructor(
    private oauthService: OAuthService,
    private configService: AuthConfigService,
    private authStorage: OAuthStorage,
    private router: Router,
    private identityUserService: IdentityUsersService,
    private sharedService: SharedServices
  ) {
    this.oauthService.events.subscribe(event => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      if (event instanceof OAuthErrorEvent) {
        console.warn(event);
      } else {
        console.info(event);
      }
    });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => {
        this.oauthService.loadUserProfile();
        if (!this.hasReceivedToken.value) {
          this.hasReceivedToken.next(true);
        }
      });

    this.oauthService.events
      .pipe(filter(e => ['token_expires'].includes(e.type)))
      .subscribe(e => this.refresh());

    this.oauthService.events
    .pipe(filter(e => ['discovery_document_loaded'].includes(e.type)))
    .subscribe(e => this.isDiscoveryDocumentLoaded = true);

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => console.log(e));

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && !this.isDiscoveryDocumentLoaded) {
          this.oauthService.configure(this.configService.authConfig);
          this.oauthService.loadDiscoveryDocument()
            .then(() => this.oauthService.setupAutomaticSilentRefresh());
      }
    });
  }

  public configureOAuthLogin() {
    this.oauthService.configure(this.configService.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  runInitialLogin(): Promise<void> {
    this.isDoneLoadingSubject$.next(false);
    return this.oauthService.loadDiscoveryDocumentAndLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          this.isDoneLoadingSubject$.next(true);
          this.identityUserService.GetUserDetails(this.getUserId()).subscribe(data => {
            this.sharedService.GetLoggedUser = data.result;
            return Promise.resolve();
          });
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(encodeURIComponent(targetUrl));
  }

  public logout() {
    this.oauthService.logOut();
  }

  public revokeTokenAndLogout() {
    this.oauthService.revokeTokenAndLogout();
  }

  public refresh(): Promise<object> {
    return this.oauthService.responseType === 'code' ? this.oauthService.refreshToken() : this.oauthService.silentRefresh();
  }

  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  public getToken() {
    const token = this.authStorage.getItem('access_token');
    if (token) return token;

    return this.oauthService.getAccessToken();
  }

  public isAuthenticated() { return !this.configService.isOauth2Enable || this.hasValidToken(); }

  public validateAccessRight(accessRight: string) {
    if ((this.hasValidToken() && this.getClaims()[accessRight]) || !this.configService.isOauth2Enable)
      return true;
  }

  public validateAccessRights(accessRights: string[]) {
    if ((this.hasValidToken() && accessRights.some(a => this.getClaims()[a])) || !this.configService.isOauth2Enable)
      return true;
  }

  public getUserId() {
    return this.getClaims()['sub'];
  }

  private getClaims() {
    if (!this.tokenClaims) {
      const token = this.authStorage.getItem('access_token');
      this.tokenClaims = jwt_decode(token);
    }
    return this.tokenClaims;
  }

  public getExpireTokenTime() {
    if (!this.expireTokenTime) {
      this.expireTokenTime = this.getClaims()['expires_in'] || 900;
    }
    return this.expireTokenTime;
  }
}
