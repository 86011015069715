import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { RoleBaseAccessRights } from '../role-base-access-rights';
import { from } from 'rxjs';
import { GlobalSharedService } from '../../services/global-shared.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MasterAPICall } from '../../shared/master-api.component';
import { SharedServices } from '../../../app/services/shared.service';
import { AccessModulesEnum } from '../../shared/enums/AccessModulesEnum';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import GlobalConfig from '../../global-config';
import { FeatureFlagEnum, FeatureFlagService } from 'src/app/services/featureFlag/feature-flag-service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public accessModulesEnum = AccessModulesEnum;
  isGAFeatureFlagOn: boolean = false;
  @Input() branding;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public accessRights: RoleBaseAccessRights,
    public globalService: GlobalSharedService,
    private sharedService: SharedServices,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService) {
    // Subscribe to role change
    this.globalService.RoleChanged.subscribe((data) => {
      if (authService.isAuthenticated()) {
        this.sharedService.masterApiResolvedCount = 0;
        this.accessRights.getAllAccessModules();
        // this.masterApiCall.CallMasterAPI();
        const token = this.authService.getToken();
        if (token) {
          this.sharedService.SetLoggedInUserRole(token);
        }

        const identityUserId = this.authService.getUserId();
        this.sharedService.SetLoggedInIdentityUserId(identityUserId);
      }
    });
  }

  public ngOnInit() {
    // jquery for side navigation
    $(() => {
      $('.side-nav .side-nav-menu li a').on('click', function(e) {
        $(this).parent().hasClass('open') ? $(this).parent().children('.dropdown-menu').slideUp(200, function() {
          $(this).parent().removeClass('open');
        }) : ($(this).parent()
          .parent().children('li.open').children('.dropdown-menu').
          slideUp(200), $(this).parent().parent().children('li.open').
            children('a').removeClass('open'), $(this).parent().parent().
              children('li.open').removeClass('open'), $(this).parent().children('.dropdown-menu').slideDown(200, function() {
                $(this).parent().addClass('open');
              }));
      }),
        $('.sidenav-fold-toggler').on('click', (e) => {
          $('.layout').toggleClass('side-nav-folded'), e.preventDefault();
        }),
        $('.sub-down li a').on('click', (e) => {
          $('.layout').removeClass('side-nav-folded'), e.preventDefault();
        }),
        // $('.sidenav-fold-toggler-profile').on('click', (e) => {
        //   $('.layout').removeClass('side-nav-folded'), e.preventDefault();
        // }),
        // $('.nav-item .dropdown-toggle.sub-menus').on('click', (e) => {
        //   $('.layout').addClass('side-nav-folded'), e.preventDefault();
        // }),
        $('.nav-item .dropdown-toggle.only-click, .overlay').on('click', (e) => {
          $('.layout').removeClass('side-nav-folded'), e.preventDefault();
        });
    });

    // tslint:disable-next-line: deprecation
    // tslint:disable-next-line: only-arrow-functions
    // tslint:disable-next-line: deprecation
    $(window).resize(() => {

      if ($(window).width() <= 1025) {

        $('.sidenav-fold-toggler-profile').on('click', (e) => {
          $('.layout').removeClass('side-nav-folded'), e.preventDefault();
        });
      }
    });

  }

  toggleClass() {
    $('#systemData').toggleClass('open');
  }

  // checks user access rights for module.
  public CheckRoleBasedAccessModule(moduleName): boolean {
    return this.authService.validateAccessRight(moduleName);
  }

  public IsUserAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  ClearAgencyFlags() {
    this.globalService.BrokerPageCanceled = false;
  }

  ClearCarrierFlags() {
    this.globalService.CarrierListingRedirection = false;
    this.globalService.PortfolioPageCanceled = false;
    this.globalService.ServiceAreaPageCanceled = false;
    this.globalService.CensusListingRedirection = false;
    this.globalService.CompositeListingRedirection = false;
    this.globalService.PortfolioCompositeListingRedirection = false;
  }

  navigate() {
    window.location.href = GlobalConfig.FORMFIRE_LEGACY_ADMIN_HOST;;
  }
}






