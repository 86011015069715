
export class TermsAndConditionsModel{
    id: number;
    file: string;
    fileUrl: string;
    termsAndConditionsType: TermsAndConditionsType;
}

export enum TermsAndConditionsType{
    'prescreen', 
    'enrollment'
}
