

<div class="container form-container text-center">

    <h1 class="mb-5">
       <strong> Access Denied</strong> </h1>
    <img class="no-access" src="./assets/imgs/no-access-rights-img.svg">
    <h3>It seems Role assigned to you is no longer active. <br>
        Please contact Formfire Admin.</h3> 

</div>