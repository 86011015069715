import { Injectable } from '@angular/core';
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { Subject } from 'rxjs/internal/Subject';
@Injectable({
  providedIn: 'root'
})

export class SpinnerServiceAdapter {

    private emitChangeSource = new Subject<any>();

    changeEmitted$ = this.emitChangeSource.asObservable();
    
    static changeEmitted$: any;

    emitChange(spinnerChange: any) {
        this.emitChangeSource.next(spinnerChange);
    }
}