import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import GlobalConfig from '../global-config';


@Injectable({
    providedIn: 'root'
  })
export class LoggingService {
    appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: GlobalConfig.APP_INSIGHTS_KEY,
                enableAutoRouteTracking: true,
                enableCorsCorrelation: true,
                
                // Excluded ID4 testing website to don't add cors headers
                // TODO: Update it with the correct url or delete it.
                correlationHeaderExcludedDomains: [
                    GlobalConfig.IDENTITY_SERVICE_HOST
                 ]
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    logEvent(name: string) {
        // not implemented
    }


    logException(exception: Error) {
        // not implemented
    }
}
