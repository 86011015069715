import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import GlobalConfig from '../global-config';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  constructor() { }

  public readonly isOauth2Enable = GlobalConfig.IS_OAUTH_ENABLED;
  public readonly issuerUrl = GlobalConfig.IDENTITY_SERVICE_HOST;
  public readonly apiUrl = window.location.origin;
  public readonly revokeTokenOnLogout = false;
  public readonly idlePercentage = 0.66;

  public authConfig: AuthConfig = {
    issuer: this.issuerUrl,
    skipIssuerCheck: true,
    redirectUri: this.apiUrl + '/#/profile',
    postLogoutRedirectUri: this.apiUrl + '/#/profile',
    silentRefreshRedirectUri: this.apiUrl + '/silent-refresh.html',
    useSilentRefresh: false,
    disablePKCE: false,
    clientId: 'admin-webapp',
    responseType: 'code',
    scope: 'openid profile offline_access IdentityServerApi AdminApi AgencyApi ProgramsApi ProposalsApi CensusesApi EmployersApi SubscriptionsApi CarriersApi IntegrationsApi',
    timeoutFactor: 0.75, // default: 0.75 - setted 0.5 for testing purposes
    showDebugInformation: true,
    strictDiscoveryDocumentValidation : false,
    sessionChecksEnabled: true,
    sessionCheckIFrameUrl: this.issuerUrl,
    sessionCheckIntervall: 15 * 1000 // in ms - default: 3 * 1000
  };
}
