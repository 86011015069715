<!--layout start-->
<div class="layout">

    <!--top header and side navigation start here-->
    <app-header [branding]="branding"></app-header>
    <app-sidebar [branding]="branding"></app-sidebar>
    <!--top header and side navigation end here-->

    <!-- Loading Spinner -->
    <div *ngIf="isLoading" class="spinner-overlay">
        <mat-spinner> </mat-spinner>
    </div>

    <!--page main content div start here-->

    <div class="page-container">
        <div class="main-content">

            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>
    <!--page main content div end here-->
</div>
<!--layout end-->


<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}" (click)="scrollToTop()">
    <i class="icon-up-arrow"></i>
</div>

<ng-template #contentPopup let-modal>
   
    <div class="modal-body session-body">
        <div class="row">
            <div class="col-3 text-center">
                <img class="alert-img" src="./assets/imgs/alert.png">
            </div>
            <div class="col-9">
                    <p class="mb-0"  *ngIf="!isSessionExpired"> <strong>Your session is about to expire!</strong>  </p>
                    <p class="mb-0"  *ngIf="!isSessionExpired">You will be logged out in <span><strong>{{countdownMessage}}</strong></span> {{countdownUnit}}.</p>
                    <p class="mb-0"  *ngIf="!isSessionExpired">Do you want to stay logged in?</p>
                    <p class="mb-0"  *ngIf="isSessionExpired"><strong>Your session has expired! </strong></p>
                    <p class="mb-0"  *ngIf="isSessionExpired">Please log in again to continue using the system.</p>
            </div>
        </div>
    </div>
  
    <div class="modal-footer no-top-border">
        <input id='btnClosedCalled' type="hidden" (click)="modal.close('Save click');"/>
       
        <button class="btn btn-primary" *ngIf="loginButtonHide"
        (click)="modal.close('Save click');RefreshToken()" >Stay Logged In</button>
        <button *ngIf="loginButtonHide" class="btn btn-secondary" (click)="modal.close('Save click');DoLogout()">Go to Login Screen </button>
        <button *ngIf="!loginButtonHide" class="btn btn-primary" (click)="modal.close('Save click');DoLogout()">Go to Login Screen </button>
    </div>
</ng-template>