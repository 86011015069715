<!-- sidebar should be hide on role base -->

<!-- Side Nav START -->
<!---menu backdrop div start-->
<div class="overlay"></div>
<!---menu backdrop div end-->
<nav class="side-nav expand-lg d-none">
    <div class="side-nav-inner" role="navigation" aria-label="Formfire Menu">
        <ul class="side-nav-menu" [ngClass]="branding ? 'branding' : ''">
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.UserManageMent)">
                <a class="dropdown-toggle only-click" routerLink="/admin-users" routerLinkActive="active-link">
                    <i class="fal fa-user-friends" ngbTooltip="Users" placement="right" container="body"></i>
                    <i class="fas fa-user-friends" ngbTooltip="Users" placement="right" container="body"></i>
                    <span class="title">Users</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.RoleManagement)">
                <a class="dropdown-toggle only-click" routerLink="/roles" routerLinkActive="active-link">
                    <i class="fal fa-id-card-alt" ngbTooltip="User Roles" container="body"></i>
                    <i class="fas fa-id-card-alt" ngbTooltip="User Roles" container="body"></i>
                    <span class="title">User Roles</span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="dropdown-toggle only-click" routerLink="/sales-code" routerLinkActive="active-link"
                *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.SalesCodeManagement)">
                    <i class="fal fa-tags" ngbTooltip="Sales Codes" container="body"></i>
                    <i class="fas fa-tags" ngbTooltip="Sales Codes" container="body"></i>
                    <span class="title">Sales Codes</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.ProgramManagement)">
                <a class="dropdown-toggle only-click" routerLink="/programs" routerLinkActive="active-link">
                    <i class="fal fa-presentation" ngbTooltip="Programs" container="body"></i>
                    <i class="fas fa-presentation" ngbTooltip="Programs" container="body"></i>
                    <span class="title">Programs</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.DocumentAndFormManagement)">
                <a class="dropdown-toggle only-click" routerLink="/document-form-type" routerLinkActive="active-link">
                    <i class="fal fa-file-alt" ngbTooltip="Document & Form Types" container="body"></i>
                    <i class="fas fa-file-alt" ngbTooltip="Document & Form Types" container="body"></i>
                    <span class="title">Document & Form Types</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule('Benefit Settings')">
                <a class="dropdown-toggle only-click" routerLink="/benefits-setting" routerLinkActive="active-link">
                    <i class="fal fa-box-usd" ngbTooltip="Benefit Settings" container="body"></i>
                    <i class="fas fa-box-usd" ngbTooltip="Benefit Settings" container="body"></i>
                    <span class="title">Benefit Settings</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.CoverageLineManagement)">
                <a class="dropdown-toggle only-click" routerLink="/coverage-lines" routerLinkActive="active-link" (click)="ClearCarrierFlags()">
                    <i class="fal fa-comment-medical" ngbTooltip="Coverage Lines" container="body"></i>
                    <i class="fas fa-comment-medical" ngbTooltip="Coverage Lines" container="body"></i>
                    <span class="title">Coverage Lines</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.CarrierManagement)">
                <a class="dropdown-toggle only-click" routerLink="/carriers" routerLinkActive="active-link">
                    <i class="fal fa-briefcase" ngbTooltip="Carriers" placement="right" container="body"></i>
                    <i class="fas fa-briefcase" ngbTooltip="Carriers" container="body"></i>
                    <span class="title">Carriers</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.AgencyManagement)">
                <a class="dropdown-toggle only-click" routerLink="/agencies" routerLinkActive="active-link" (click)="ClearAgencyFlags()">
                    <i class="fal fa-user-shield" ngbTooltip="Agencies" container="body"></i>
                    <i class="fas fa-user-shield" ngbTooltip="Agencies" container="body"></i>
                    <span class="title">Agencies</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.ProductManagement)">
                <a class="dropdown-toggle only-click" routerLink="/products" routerLinkActive="active-link">
                    <i class="fal fa-notes-medical" ngbTooltip="Products" container="body"></i>
                    <i class="fas fa-notes-medical" ngbTooltip="Products" container="body"></i>
                    <span class="title">Products</span>
                </a>
            </li>
            <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.SubscriptionManagement)">
                    <a class="dropdown-toggle only-click" routerLink="/subscription-management" routerLinkActive="active-link">
                        <i class="fal fa-cash-register" ngbTooltip="Subscriptions" container="body"></i>
                        <i class="fas fa-cash-register" ngbTooltip="Subscriptions" container="body"></i>
                        <span class="title">Subscriptions</span>
                    </a>
                </li>
                <li class="nav-item dropdown"  routerLinkActive="active-link"  id="systemData"  [hidden]="!CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                    <a class="dropdown-toggle">
                        <i class="fal fa-table" ngbTooltip="System Data" container="body"></i>
                        <i class="fas fa-table" ngbTooltip="System Data" container="body"></i>
                        <span class="title">System Data</span>
                        <span class="arrow">
                            <i class="fas fa-chevron-down"></i>
                        </span>
                    </a>
                    <ul class="dropdown-menu sub-down" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                        <li class="nav-item dropdown" >
                            <a class="dropdown-toggle" routerLink="/language" routerLinkActive="active-link">
                                <i class="fal fa-globe" ngbTooltip="Languages" placement="right" container="body"></i>
                                <i class="fas fa-globe" ngbTooltip="Languages" container="body"></i>
                                <span class="title">Languages</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/rating-type" routerLinkActive="active-link" >
                                <i class="fal fa-star" ngbTooltip="Rating Types" placement="right" container="body"></i>
                                <i class="fas fa-star" ngbTooltip="Rating Types" container="body"></i>
                                <span class="title">Rating Types</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/address-type" routerLinkActive="active-link" >
                                <i class="fal fa-address-book" ngbTooltip="Address Types" placement="right" container="body"></i>
                                <i class="fas fa-address-book" ngbTooltip="Address Types" container="body"></i>
                                <span class="title">Address Types</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/county" routerLinkActive="active-link">
                                <i class="fal fa-map-marked" ngbTooltip="Counties" placement="right" container="body"></i>
                                <i class="fas fa-map-marked" ngbTooltip="Counties" container="body"></i>
                                <span class="title">Counties</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/zips" routerLinkActive="active-link">
                                <i class="fal fa-map-marker-alt" ngbTooltip="Zips" placement="right" container="body"></i>
                                <i class="fas fa-map-marker-alt" ngbTooltip="Zips" container="body"></i>
                                <span class="title">Zips</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/tier-structure" routerLinkActive="active-link">
                                <i class="fal fa-folder-tree" ngbTooltip="Tier Structures" placement="right" container="body"></i>
                                <i class="fas fa-folder-tree" ngbTooltip="Tier Structures" container="body"></i>
                                <span class="title">Tier Structures</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.MasterManagement)">
                            <a class="dropdown-toggle" routerLink="/formfire-census" routerLinkActive="active-link">
                                <i class="fal fa-poll-people" ngbTooltip="FormFire Censuses" placement="right" container="body"></i>
                                <i class="fas fa-poll-people" ngbTooltip="FormFire Censuses" container="body"></i>
                                <span class="title">FormFire Censuses</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                        <a class="dropdown-toggle only-click" *ngIf="CheckRoleBasedAccessModule(accessModulesEnum.StateLevelCompositeFactor)" routerLink="/state-level-composites-factor" routerLinkActive="active-link">
                            <i class="fal fa-layer-group" ngbTooltip="State Level Composites" container="body"></i>
                            <i class="fas fa-layer-group" ngbTooltip="State Level Composites" container="body"></i>
                            <span class="title">State Level Composites</span>
                        </a>
                    </li>
            <li class="nav-item dropdown">
                <a class="dropdown-toggle only-click" routerLink="/healthcheck" routerLinkActive="active-link">
                    <i class="fal fa-signal" ngbTooltip="Health Check" container="body"></i>
                    <i class="fas fa-signal" ngbTooltip="Health Check" container="body"></i>
                    <span class="title">Health Check</span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="dropdown-toggle only-click" routerLink="/system-settings" routerLinkActive="active-link">
                    <i class="fal fa-cogs" ngbTooltip="System Settings" container="body"></i>
                    <i class="fas fa-cogs" ngbTooltip="System Settings" container="body"></i>
                    <span class="title">System Settings</span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="dropdown-toggle only-click" (click)="navigate()">
                    <i class="fal fa-clipboard-list"></i>
                    <i class="fas fa-clipboard-list"></i>
                    <span class="title">FormFire Admin</span>
                </a>
            </li>
        </ul>
        <div class="fold-menu-on-desktop" [ngClass]="branding ? 'branding' : ''">
            <a class="sidenav-fold-toggler" href="javascript:void(0);">
                <i class="fas fa-arrow-alt-left fa-2x"></i>
            </a>
        </div>
    </div>
</nav>
<!-- Side Nav END -->
