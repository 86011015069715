<!-- sidebar should be hide on role base -->

<!-- header start -->
<header class="header d-none">
  <div class="header-container" [ngClass]="branding ? 'branding' : ''">
    <div class="nav-logo">
      <!-- <a class="sidenav-fold-toggler" href="javascript:void(0);">
          <i class="icon-menu"></i>
      </a> -->
      <a class="logo" href="javascript:void(0)" title="Quote Plus Logo">
        <img src="./assets/imgs/logos/quote-plus-green-gray.png" alt="Quote Plus Logo" *ngIf="!branding">
        <img src="./assets/imgs/logos/Quote-Plus-Green-Black.svg" alt="Quote Plus Logo" *ngIf="branding">
      </a>
    </div>
    <ul class="nav-right">
      <li class="hide-on-device d-none">
        <a href="javascript:void(0)" title="Help">
          <i class="fad fa-question-circle fa-2x" placement="bottom" ngbTooltip="Help"></i>
        </a>
      </li>
      <li class="hide-on-device setting" ngbDropdown autoClose="outside">
        <!-- <a href="javascript:void(0)" id="fontDropdown" ngbDropdownToggle>
          <i class="fad fa-cog fa-2x" placement="bottom" ngbTooltip="Settings"></i>
        </a> -->
        <ul class="dropdown-menu font-size-btns" ngbDropdownMenu aria-labelledby="fontDropdown">
          <!-- font size increase and decrease code here -->
          <li>
            <button type="button" class="btn increase" placement="bottom" ngbTooltip="Increase Font Size">
              <i class="fal fa-font-case"></i>
              <i class="fal fa-long-arrow-up"></i>
            </button>
            <button type="button" class="btn resetMe" placement="bottom" ngbTooltip="Reset Font Size">
              <i class="fal fa-font-case"></i>
            </button>
            <button type="button" class="btn decrease" placement="bottom" ngbTooltip="Decrease Font Size">
                <i class="fal fa-font-case"></i>
                <i class="fal fa-long-arrow-down"></i>
            </button>
          </li>
          <!-- font size increase and decrease code end here -->
        </ul>

      </li>
      <!-- profile dropdown-menu here -->
      <li class="user-profile" ngbDropdown>
        <span class="user-name">{{ getUserName() }}</span>
        <a href="javascript:void(0)" class="dropdown-toggle sidenav-fold-toggler-profile"  id="userDropdown" ngbDropdownToggle>
         <span class="profile-img" *ngIf="this.sharedService.GetAvatar == null || this.sharedService.GetAvatar == ''"> <img src="./assets/imgs/avatar/profile-thumb.png" alt="User Profile Photograph"></span>
         <span class="profile-img" *ngIf="this.sharedService.GetAvatar != null && this.sharedService.GetAvatar != ''"> <img [src]="domSanitizer.bypassSecurityTrustUrl(this.sharedService.GetAvatar)" alt="User Profile Photograph"></span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="userDropdown">
          <li class="user-name-device">
          <span  class="profile-img" *ngIf="this.sharedService.GetAvatar == null || this.sharedService.GetAvatar == ''" ><img  src="./assets/imgs/avatar/profile-thumb.png" alt="User Profile Photograph"></span>  
           <span  class="profile-img" *ngIf="this.sharedService.GetAvatar != null && this.sharedService.GetAvatar != ''"><img [src]="domSanitizer.bypassSecurityTrustUrl(this.sharedService.GetAvatar)" alt="User Profile Photograph"></span> 
            <div class="user-info">
              <a href="javascript:void(0)" ngbDropdownItem title="User Name"> {{ getUserName() }} </a>
              <!-- <div class="user-role">System Admin</div> -->
            </div>
          </li>
          <li><a href="javascript:void(0)" routerLink="/profile" ngbDropdownItem> <i class="fad fa-user fa-lg"></i> Profile</a></li>
          <li class="d-none"><a href="javascript:void(0)" ngbDropdownItem> <i class="fad fa-envelope fa-lg"></i> Inbox</a></li>
          <li class="d-none"><a href="javascript:void(0)" ngbDropdownItem> <i class="fad fa-cog fa-lg"></i> Setting</a>
          </li>
          <li class="d-none"><a href="javascript:void(0)" ngbDropdownItem> <i class="fad fa-question-circle fa-lg"></i>
              Help</a></li>
          <li><a ngbDropdownItem (click)="DoLogout()"><i class="fad fa-sign-out-alt fa-lg"></i> Logout</a>
          </li>
        </ul>
      </li>
      <li class="menu-toggle-li">
        <a class="sidenav-fold-toggler" href="javascript:void(0);">
          <i class="fal fa-bars fa-lg"></i>
        </a>
      </li>
    </ul>
  </div>
</header>
<!-- header end -->