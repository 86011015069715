<footer id="page-footer" class="container-fluid">
  <ul class="list-inline float-left">
      <li class="list-inline-item">© {{year}} FormFire |</li>
      <li class="list-inline-item">
          <a target="_blank" href="https://www.formfire.com/privacy">
              Privacy
          </a> |
      </li>
      <li class="list-inline-item">
          <a target="_blank" href="https://www.formfire.com/security">
              Security Info
          </a>
      </li>
  </ul>
</footer>