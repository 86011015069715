import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { camelCase } from 'jquery';

class RestResponse<T> implements IRestResponse<T> {
  constructor(
    public statusCode: number,
    public message: string,
    public isError: boolean,
    public error?: IError,
    public result?: T
  ) {}
}

export interface IRestResponse<T> {
  statusCode: number;
  message: string;
  isError: boolean;
  error?: IError;
  result?: T;
}

export interface IError {
  message: string;
  validationErrors: IValidationErrors;
}

export interface IValidationErrors {
  [key: string]: string | string[];
}

interface IRawRestResponse<T> {
  statusCode: number;
  message: string;
  isError: boolean;
  error?: IRawApiError;
  result?: T;
}

interface IRawApiError {
  message: string;
  validationErrors: IRawValidationError[];
}

interface IRawValidationError {
  name: string;
  reason: string;
}

// TODO: move calls to map() into HttpInterceptor
// TODO: Get the hostname in a better way
@Injectable({
  providedIn: 'root',
})
export class FormFireApiService {
  constructor(private httpClient: HttpClient) {}
  headers = new HttpHeaders({ 'api-version': '2.0' });

  public get<T>(
    url: string,
    parameters?: HttpParams
  ): Observable<IRestResponse<T>> {
    return this.httpClient
      .get<IRawRestResponse<T>>(url, {
        params: parameters,
        headers: this.headers,
      })
      .map((v) => {
        this.assertIsIRawRestResponse(v);
        return this.mapRawResponse<T>(v);
      });
  }

  public post<T>(url: string, body?: object): Observable<IRestResponse<T>> {
    return this.httpClient
      .post<IRawRestResponse<T>>(url, body, {
        headers: this.headers,
      })
      .map((v) => {
        this.assertIsIRawRestResponse(v);
        return this.mapRawResponse<T>(v);
      });
  }

  public put<T>(
    url: string,
    body?: object,
    parameters?: HttpParams
  ): Observable<IRestResponse<T>> {
    return this.httpClient
      .put<IRawRestResponse<T>>(url, body, {
        params: parameters,
        headers: this.headers,
      })
      .map((v) => {
        this.assertIsIRawRestResponse(v);
        return this.mapRawResponse<T>(v);
      });
  }

  public delete<T>(
    url: string,
    body?: object,
    parameters?: HttpParams
  ): Observable<IRestResponse<T>> {
    return this.httpClient
      .request<IRawRestResponse<T>>('delete', url, {
        params: parameters,
        headers: this.headers,
        body,
      })
      .map((v) => {
        this.assertIsIRawRestResponse(v);
        return this.mapRawResponse<T>(v);
      });
  }

  private mapRawResponse<T>(response: IRawRestResponse<T>): IRestResponse<T> {
    const restResponse: IRestResponse<T> = new RestResponse<T>(
      response.statusCode,
      response.message,
      response.isError,
      response.isError ? this.toValidationErrors(response.error) : undefined,
      response.isError ? undefined : response.result
    );
    return restResponse;
  }

  private assertIsIRawRestResponse(value: any): value is IRawRestResponse<any> {
    if (
      !(value as IRawRestResponse<any>).statusCode &&
      !(value as IRawRestResponse<any>).message &&
      !(value as IRawRestResponse<any>).isError &&
      (!(value as IRawRestResponse<any>).error ||
        !(value as IRawRestResponse<any>).result)
    ) {
      throw new Error('Value is not a valid IRawRestResponse');
    }
    return true;
  }

  private toValidationErrors(error: IRawApiError) {
    const errors: IValidationErrors = undefined;
    if (error.validationErrors) {
      error.validationErrors.forEach((v) => {
        const name = camelCase(v.name);
        if (errors[name]) {
          if (Array.isArray(errors[name])) {
            errors[name] = [...(errors[name] as string[]), v.reason];
          } else {
            errors[name] = [errors[name] as string, v.reason];
          }
        } else {
          errors[name] = v.reason;
        }
      });
    }
    const validationErrors = {
      message: error.message,
      validationErrors: errors,
    } as IError;
    return validationErrors;
  }
}
