import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginViewModel } from '../shared/module/LoginViewModel';
import { OtpModel } from '../shared/module/OtpModel';
import { Observable } from 'rxjs/Observable';
import { ApiService, HttpReqMethod } from '../services/api.service';
import * as jwt_decode from 'jwt-decode';
import { throwError } from 'rxjs';
import { RoleTypeEnum } from '../shared/enums/RoleTypeEnum';
import { AppSettings } from '../../app/AppSettings';
import { EntityNameEnum } from '../shared/enums/EntityNameEnum';
import { UserTempPasswordModel } from '../shared/module/UserTempPasswordModel';
import GlobalConfig from '../global-config';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isActionTakenTime: Date;
  appSettings: AppSettings = new AppSettings();
  token: string;
  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {}
  // Authenticate FF user
  public AUTF55FA3C03FA748D6844A9FF(model: LoginViewModel) {
    model.roleType = RoleTypeEnum.FormFireUsers;
    const date = new Date();
    model.otpExpirationTime = date.toISOString();

    const encObj = JSON.stringify(model).replace(/\{/g, '{{').replace(/\}/g, '}}');
    const obj = { body: encObj };
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST + 'api/authentication/login',
      HttpReqMethod.POST.toString(),
      obj
    );
  }

  // verify two factor Auth code
  public VLCBA097400A7F411A89077BFF(model: OtpModel) {
    const date = new Date();
    model.CurrentTime = date.toISOString();
    const otpModel = Object.assign({}, model);
    otpModel.roleType = RoleTypeEnum.FormFireUsers;
    const encObj = JSON.stringify(otpModel).replace(/\{/g, '{{').replace(/\}/g, '}}');
    const obj = { body: encObj };
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST + 'api/authentication/ValidateOtpCode',
      HttpReqMethod.POST.toString(),
      obj
    );
  }

  // resend two fact Auth verification code
  public REMT680OTPEF1BD044F93E63FF(model: any) {
    const date = new Date();
    model.otpExpirationTime = date.toISOString();
    const otpModel = Object.assign({}, model);
    otpModel.roleType = RoleTypeEnum.FormFireUsers;
    const encObj = JSON.stringify(otpModel).replace(/\{/g, '{{').replace(/\}/g, '}}');
    const obj = { body: encObj };
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST + 'api/authentication/ResendOtpCode',
      HttpReqMethod.POST.toString(),
      obj
    );
  }

  // extend user session time
  EXTDTTFEB4EE3205D41A813CFF(tokenMinute: number) {
    // Token endpoint & params.
    const tokenEndpoint: string =
      GlobalConfig.USERS_SERVICE_HOST +
      'api/authentication/rtts8c09ed38bb654b25a4f2c9923ebb5c8c?param=';
    if (this.IsAuthenticated() === true) {
      tokenMinute = Math.ceil(tokenMinute);
      // const tokenAddMinute = this.security.EB1671C7319164AA0AB79(tokenMinute.toString());
      this.apiService
        .Request<any>(tokenEndpoint + tokenMinute, HttpReqMethod.GET.toString())
        .subscribe(
          (data) => {
            if (data !== 'undefined' && data !== null) {
              if (
                data.ts8c09ed38bb654b25a4f2c9923ebb5c8c !== 'undefined' &&
                data.ts8c09ed38bb654b25a4f2c9923ebb5c8c !== null
              ) {
                this.Store(data.ts8c09ed38bb654b25a4f2c9923ebb5c8c);
              }
            }
          },
          (error) => {
            if (error.status === 401) {
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('loggedInUserName');
              window.location.href = '/#/profile'; // location.replace('/#/profile');
              // window.location.reload();
            }
            if (error.status === 500) {
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('loggedInUserName');
              window.location.href = '/#/profile'; // location.replace('/#/profile');
              // window.location.reload();
            }
            // Error on post request.
            return throwError(error);
          }
        );
    }
  }

  // Store Token in local storage
  public Store(token: any) {
    sessionStorage.setItem('token', token);
  }

  // Get Login status
  IsAuthenticated(): boolean {
    let token1;
    const sessionStorageToken = sessionStorage.getItem('token');
    if (
      sessionStorageToken !== null &&
      sessionStorageToken !== undefined &&
      sessionStorageToken !== ''
    ) {
      token1 = sessionStorageToken;
    }

    if (
      token1 !== undefined &&
      token1 !== null &&
      token1 !== '' &&
      token1 !== 'null'
    ) {
      const decoded = jwt_decode(token1);
      const tokenExpirationDateTime = new Date(decoded.exp * 1000);
      const currentDateTime = new Date();
      const milisecondsDiff =
        tokenExpirationDateTime.getTime() - currentDateTime.getTime();
      const loginTimeRemaining = milisecondsDiff / 1000 / 60;

      if (Math.ceil(loginTimeRemaining) <= 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  // Remove Authentication Token from local storage
  ClearToken() {
    sessionStorage.removeItem('token');
  }

  get LoggedInUsername(): string {
    return sessionStorage.getItem('loggedInUserName');
  }
  SetLoggedInUsername(val: string) {
    sessionStorage.setItem('loggedInUserName', val);
  }

  // Call signout service
  public signout(): Observable<any> {
    return this.httpClient.get(
      GlobalConfig.USERS_SERVICE_HOST + 'api/authentication/SignOut'
    );
  }

  get UserActionTaken() {
    return this.isActionTakenTime;
  }
  set UserActionTaken(isActionTaken: Date) {
    this.isActionTakenTime = isActionTaken;
  }

  IsValidToken() {
    const sessionStorageToken = sessionStorage.getItem('token');
    if (
      sessionStorageToken !== null &&
      sessionStorageToken !== undefined &&
      sessionStorageToken !== ''
    ) {
      this.token = sessionStorageToken;
    }
    if (this.token != null && this.token !== undefined) {
      const decoded = jwt_decode(this.token);
      const tokenExpirationDateTime = new Date(decoded.exp * 1000);
      const currentDateTime = new Date();
      const milisecondsDiff =
        tokenExpirationDateTime.getTime() - currentDateTime.getTime();
      const loginTimeRemaining = milisecondsDiff / 1000 / 60;
      if (Math.ceil(loginTimeRemaining) < 0) {
        this.UserActionTaken = null;
      } else {
        this.UserActionTaken = new Date();
      }
    }
  }

  PLSC70E27848436452CHABIS() {
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST +
        'api/authentication/PLSC70E27848436452CHABIS',
      HttpReqMethod.GET.toString()
    );
  }

  // set two factor
  S2FA5C62TEMPAF354E61PWD90A(
    userTempPasswordModel: UserTempPasswordModel,
    isTwoFactorAuthentication: boolean,
    entityName: string
  ) {
    const model = {
      UserId: userTempPasswordModel.userId,
      IsTwoFactorAuthentication: isTwoFactorAuthentication,
      UserName: userTempPasswordModel.userName,
      RoleName: userTempPasswordModel.userRoleName,
    };
    const encObj = JSON.stringify(model).replace(/\{/g, '{{').replace(/\}/g, '}}');
    const payload = { body: encObj };
    if (entityName === EntityNameEnum.FormFireUser) {
      return this.apiService.Request<any>(
        GlobalConfig.USERS_SERVICE_HOST +
          'api/authentication/SetTwoFactorAuthenticationForFormFireUser',
        HttpReqMethod.POST.toString(),
        payload
      );
    } else if (entityName === EntityNameEnum.Agency) {
      return this.apiService.Request<any>(
        GlobalConfig.USERS_SERVICE_HOST +
          'api/authentication/SetTwoFactorAuthenticationForAgencyUser',
        HttpReqMethod.POST.toString(),
        payload
      );
    } else if (entityName === EntityNameEnum.GeneralAgency) {
      return this.apiService.Request<any>(
        GlobalConfig.USERS_SERVICE_HOST +
          'api/authentication/SetTwoFactorAuthenticationForGeneralAgencyUser',
        HttpReqMethod.POST.toString(),
        payload
      );
    } else {
      return this.apiService.Request<any>(
        GlobalConfig.USERS_SERVICE_HOST +
          'api/authentication/SetTwoFactorAuthenticationFromProfile',
        HttpReqMethod.POST.toString(),
        payload
      );
    }
  }
  VerifyUserAccount(userId) {
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST +
        'api/authentication/VerifyUserAccount?userId=' +
        userId,
      HttpReqMethod.GET.toString()
    );
  }
  IsProxyLogin(loggedInUserId: number, proxyUserId: number) {
    const model = {
      LoggedInUserId: loggedInUserId,
      IsProxy: false,
      ProxyUserId: proxyUserId,
    };
    return this.apiService.Request<any>(
      GlobalConfig.USERS_SERVICE_HOST + 'api/authentication/ProxyLogin',
      HttpReqMethod.POST.toString(),
      model
    );
  }
}
