export enum AccessModulesEnum {
    MasterManagement = 'Master Data Management',
    UserManageMent = 'User Management',
    RoleManagement = 'Role Management',
    ProductManagement = 'Product Management',
    CoverageLineManagement = 'Coverage Lines',
    CarrierManagement = 'Carriers',
    ProgramManagement = 'Programs',
    DocumentAndFormManagement = 'Document & Form Types',
    SalesCodeManagement = 'Sales Code',
    AgencyManagement = 'Agencies',
    GeneralAgencyManagement = 'General Agencies',
    SubscriptionManagement = 'Subscriptions',
    TierStructuresManagement = 'Tier Structures',
    StateLevelCompositeFactor = 'State Level Composite Factors',
    EmployerManagement = 'Employer Management',
    ProxyLogin = 'Proxy Login'
}
