import { OnInit, Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { IdentityRolesService } from '../services/identity-roles.service';
import { SharedServices } from '../services/shared.service';
import { AccessModulesEnum } from './enums/AccessModulesEnum';
import { RoleTypeEnum } from './enums/RoleTypeEnum';
import { AccessModulesAndAccessRights } from './module/AccessModulesAndAccessRights';

@Injectable({
  providedIn: 'root'
})

export class RoleBaseAccessRights implements OnInit {
  public accessiableModules: string[];
  public userAccessRights: string[];
  public proxyUserAccessRights: string[];

  constructor(private identityRoleService: IdentityRolesService) { }
  
  public ngOnInit() { }

  public getAllAccessModules() {
    this.identityRoleService.GetRolesTypes().subscribe(roleTypes => {
        const formFireUserRoleType = roleTypes.result.find(rt => rt.itemName === RoleTypeEnum.FormFireUsers).id;
        this.identityRoleService.GetAllAccessModules(formFireUserRoleType).subscribe(data => {
            this.accessiableModules = [];
            const modules = data.result;
            for (let i = 0, len = modules.length; i < len; i++) {
                this.accessiableModules.push(modules[i].accessModuleName);
            };
        }, error => {
            console.error(error.error.errorMessage);
        });
    }, error => {
        console.error(error.error.errorMessage);
    });
}

// get all access Rights based on loged in user role  and access module
public getAllAccessRights(moduleName: string) {
    this.identityRoleService.GetRolesTypes().subscribe(roleTypes => {
        const formFireUserRoleType = roleTypes.result.find(rt => rt.itemName === RoleTypeEnum.FormFireUsers).id;
        this.identityRoleService.GetAllAccessModules(formFireUserRoleType).subscribe(data => {
            this.userAccessRights = [];
            const modules = data.result;
            let rights: AccessModulesAndAccessRights[] = [];
            modules.forEach(m => rights.concat(m.accessModulesAndAccessRightsList));

            if (rights && rights.length > 0) {
                this.userAccessRights = [];
                let i = 0;
                for (i = 0; i < rights.length; i++) {
                    this.userAccessRights.push(rights[i].accessRightName);
                }
            }
        }, error => {
            console.error(error.error.errorMessage);
        });
    }, error => {
        console.error(error.error.errorMessage);
    });
  }

  // check accessibility for accessrights on the basis of logedin user role base accessmodule
  public CheckIsValidProxyLoginAccessRights(accessRightName: string): boolean {
    if (this.proxyUserAccessRights != null && this.proxyUserAccessRights !== undefined) {
      let i = 0;
      for (i = 0; i < this.proxyUserAccessRights.length; i++) {
        if (this.proxyUserAccessRights[i] === accessRightName) {
          return true;
        }
      }
    }
    return false;
  }

  public DispalySideBarAndHeader() {
    $('header').removeClass('d-none');
    $('nav').removeClass('d-none');
    $('footer#page-footer').removeClass('d-none');
  }
  public HideSideBarAndHeader() {
    $('header').addClass('d-none');
    $('nav').addClass('d-none');
    $('footer#page-footer').addClass('d-none');
  }
}






