import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { CarrierModel } from '../shared/module/Carriers/CarrierModel';
import { Injectable } from "@angular/core";

@Injectable()
export class GlobalSharedService {
  isRoleChanged: any;
  RoleChanged: Observable<any>;
  RolesChangeObserver: any;
  carrierModel: CarrierModel = new CarrierModel();
  isFormsPage: boolean;
  isDeactivated: boolean;
  isCensusPage = false;
  isCompositePage = false;
  isPortfolioCompositePage = false;
  isPortfolioPage: boolean;
  isFromBrokerPage: boolean;
  PortfolioPageChanged: Observable<any>;
  PortfolioPageChangeObserver: any;
  serviceAreaPageChanges: boolean;
  carrierPortfolioDetails: any;
  carrierName: string;
  agencyName: string;
  carrierPortfolioName: string;
  carrierServiceAriaName: string;
  brokerName: string;
  agencyActiveStatus: boolean;
  isAgeBandedTab: boolean;
  isCoverageTierTab: boolean;
  productName: string;

  constructor() {
    this.isPortfolioPage = false;
    this.RoleChanged = new Observable((observer: Observer<any>) => {
      this.RolesChangeObserver = observer;
    });
  }

  // Observes any change
  SetRoleChanged(data: any) {
    this.RoleChanged = data;
    this.RolesChangeObserver.next(this.RoleChanged);
  }

  get PortfolioPageCanceled(): boolean {
    return this.isPortfolioPage;
  }
  set PortfolioPageCanceled(val: boolean) {
    this.isPortfolioPage = val;
  }

  get ServiceAreaPageCanceled(): boolean {
    return this.serviceAreaPageChanges;
  }

  set ServiceAreaPageCanceled(val: boolean) {
    this.serviceAreaPageChanges = val;
  }

  get CarrierListingRedirection(): boolean {
    return this.isFormsPage;
  }
  set CarrierListingRedirection(val: boolean) {
    this.isFormsPage = val;
  }
  get CensusListingRedirection(): boolean {
    return this.isCensusPage;
  }
  set CensusListingRedirection(val: boolean) {
    this.isCensusPage = val;
  }
  get CompositeListingRedirection(): boolean {
    return this.isCompositePage;
  }
  set CompositeListingRedirection(val: boolean) {
    this.isCompositePage = val;
  }
  get BrokerPageCanceled(): boolean {
    return this.isFromBrokerPage;
  }
  set BrokerPageCanceled(val: boolean) {
    this.isFromBrokerPage = val;
  }

  get GetCarrierName(): string {
    return this.carrierName;
  }

  set SetCarrierName(val: string) {
    this.carrierName = val;
  }

  get GetAgencyName(): string {
    return this.agencyName;
  }

  set SetAgencyName(val: string) {
    this.agencyName = val;
  }
  set SetCarrierPortfolioDetails(carrierModel: CarrierModel) {
    this.carrierModel = carrierModel;
  }
  get GetCarrierPortfolioDetails(): CarrierModel {
    return this.carrierModel;
  }

  get GetCarrierPortfolioName(): string {
    return this.carrierPortfolioName;
  }

  set SetCarrierPortfolioName(val: string) {
    this.carrierPortfolioName = val;
  }
  set SetCarrierServiceAriaName(val: string) {
    this.carrierServiceAriaName = val;
  }
  set SetBrokerName(val: string) {
    this.brokerName = val;
  }
  get GetBrokerName(): string {
    return this.brokerName;
  }
  get GetCarrierServiceAriaName(): string {
    return this.carrierServiceAriaName;
  }

  get PortfolioCompositeListingRedirection(): boolean {
    return this.isPortfolioCompositePage;
  }
  set PortfolioCompositeListingRedirection(val: boolean) {
    this.isPortfolioCompositePage = val;
  }
  get DeactivatedValue(): boolean {
    return this.isDeactivated;
  }
  set DeactivatedValue(val: boolean) {
    this.isDeactivated = val;
  }

  get GetAgencyStatus(): boolean {
    return this.agencyActiveStatus;
  }

  set SetAgencyStatus(val: boolean) {
    this.agencyActiveStatus = val;
  }
  set SetIsAgeBandedTab(val: boolean) {
    this.isAgeBandedTab = val;
  }
  get GetIsAgeBandedTab(): boolean {
    return this.isAgeBandedTab;
  }
  set SetIsCoverageTierTab(val: boolean) {
    this.isCoverageTierTab = val;
  }
  get GetIsCoverageTierTab(): boolean {
    return this.isCoverageTierTab;
  }
}
